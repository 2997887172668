import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


interface CategoryNavigationProps {
    categories: any[];
}

const CategoryNavigation: React.FC<CategoryNavigationProps> = ({
    categories,
}) => {
    const navigate = useNavigate();

    const [hoverCategoryId, setHoverCategoryId] = useState<number | null>(null);
    const [subSubCategories, setSubSubCategories] = useState<any[]>([]);

    useEffect(() => {
        // console.log('categories', categories);
    }, [categories]);

    useEffect(() => {
        if (hoverCategoryId !== null) {
            const foundSubSubCategories = categories.filter(c => c.c_depth === 3 && Number.parseInt(c.c_parent) === hoverCategoryId);
            setSubSubCategories(foundSubSubCategories);
            // console.log('subSubCategories', foundSubSubCategories);
        } else {
            setSubSubCategories([]);
        }
    }, [hoverCategoryId, categories]);

    const handleSubCategoryClick = (mainCategory: string, subCategory: string) => {
        navigate('/comparsion', { state: { mainCategory, subCategory } });
    }

    const handleSubSubCategoryClick = (mainCategory: string, subCategory: string, subSubCategory: string) => {
        navigate('/comparsion', { state: { mainCategory, subCategory, subSubCategory } });
    }

    return (
        <div
            className='group flex relative w-full h-[56px] text-[#7F7F7F] justify-center'
            onMouseLeave={() => setHoverCategoryId(null)}
        >
            <div className='w-[1280px] h-full flex justify-between items-center'>
                {categories.filter(c => c.c_depth === 1).map((category) => (
                    <div
                        key={category.c_uid}
                        className={`flex flex-col pt-1 gap-2 w-full h-full items-center justify-center border-b-4 ${hoverCategoryId === category.c_uid ? 'border-[#7F7F7F]' : 'border-transparent'} hover:border-[#7F7F7F] hover:cursor-pointer`}
                        onMouseEnter={() => setHoverCategoryId(category.c_uid)}
                    >
                        <span className='text-lg font-semibold h-full content-center'>{category.c_name}</span>
                    </div>
                ))}
            </div>
            <div className='absolute top-[56px] w-full h-[350px] bg-white m-auto gap-2 invisible flex justify-center group-hover:visible'>
                <div className='w-[1280px] gap-2 flex'>
                    {categories.filter(c => c.c_depth === 2 && Number.parseInt(c.c_parent) === hoverCategoryId).map((subCategory) => (
                        <div key={subCategory.c_uid} className='w-[100px] flex flex-col gap-2 p-2'>
                            <div
                                className='text-sm font-bold hover:cursor-pointer'
                                onClick={() => handleSubCategoryClick(subCategory.c_parent, subCategory.c_uid)}>
                                {subCategory.c_name}
                            </div>
                            {categories.filter(c => c.c_depth === 3 && Number.parseInt(c.c_parent.split(',')[1]) === subCategory.c_uid).map((subSubCategory) => (
                                <div key={subSubCategory.c_uid} className='flex flex-col hover:cursor-pointer'>
                                    <div
                                        className='text-sm hover:font-semibold'
                                        onClick={() => handleSubSubCategoryClick(subCategory.c_parent, subCategory.c_uid, subSubCategory.c_uid)}
                                    >
                                        {subSubCategory.c_name}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CategoryNavigation;

import React, { useEffect, useState } from 'react';

interface TagInputProps {
  tags: any[];
  toggled: string[];
  displayProperty: string;
  onTagAdded: (newTag: any) => void;
  onTagRemoved: (removedTagId: any) => void;
}

const TagInput: React.FC<TagInputProps> = ({
  tags: initialTags,
  toggled: initialToggled,
  displayProperty,
  onTagAdded,
  onTagRemoved,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState<any[]>(initialTags);

  const [isInputVisible, setInputVisible] = useState(false);

  useEffect(() => {
    setTags(initialTags)


  }, [initialTags, initialToggled]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const newTag = { [displayProperty]: inputValue.trim() };
      if (!tags.some(tag => tag[displayProperty] === newTag[displayProperty])) {
        const updatedTags = [...tags, newTag];
        setTags(updatedTags);
        setInputValue('');
        onTagAdded(newTag);
      }
    }
  };

  const handleRemoveTag = (index: number) => {
    const newTags = [...tags];
    const removedTag = newTags[index];
    newTags.splice(index, 1);
    setTags(newTags);
    onTagRemoved(removedTag);
  };

  return (
    <div className="text-sm flex items-center flex-wrap gap-2">
      {tags.map((tag, index) => (
        <div
          key={index}
          className="flex items-center text-center bg-[#F0F0F0] px-4 py-2 text-[#898989] rounded-full "
        >
          <div className='flex gap-3'>
            <span>{tag[displayProperty]}</span>
            <button
              className="text-[#737373]"
              onClick={() => handleRemoveTag(index)}
            >
              &times;
            </button>
          </div>
        </div>
      ))}
      {isInputVisible ? (
        <input
          type="text"
          className="text-center  bg-white px-4 py-1 h-8 text-black rounded-xl border-black border-[1px]"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          onBlur={() => setInputVisible(false)}
          autoFocus
        />
      ) : (
        <button
          className="text-center bg-[#6F6F6F] px-4 py-2 text-white rounded-full"
          onClick={() => setInputVisible(true)}
        >
          추가
        </button>
      )}
    </div>
  );
};

export default TagInput;

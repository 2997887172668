import React, { useState, ReactNode, cloneElement, ReactElement } from 'react';

interface MouseOverModalProps {
  children: ReactElement;
  renderContent: (content: React.ReactNode) => React.ReactNode;
}

const MouseOverModal: React.FC<MouseOverModalProps> = ({ children, renderContent }) => {
  const [modalPosition, setModalPosition] = useState<{ x: number, y: number } | null>(null);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);

  const handleMouseOver = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, content: React.ReactNode) => {
    const x = event.clientX;
    const y = event.clientY;
    const modalWidth = 200; 
    const modalHeight = 100; 
    
    const adjustedX = x + modalWidth > window.innerWidth ? x - modalWidth : x;
    const adjustedY = y + modalHeight > window.innerHeight ? y - modalHeight : y;
    
    setModalPosition({ x: adjustedX, y: adjustedY });
    setModalContent(renderContent(content));
  };

  const handleMouseOut = () => {
    setModalPosition(null);
    setModalContent(null);
  };

  return (
    <div className="relative">
      {cloneElement(children, {
        onMouseOver: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleMouseOver(e, children.props.children),
        onMouseOut: handleMouseOut,
      })}
      {modalPosition && modalContent && (
        <div
            className='pointer-events-none'
          onMouseOver={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
          onMouseOut={handleMouseOut}
          style={{
            position: 'fixed',
            top: modalPosition.y,
            left: modalPosition.x,
            width: 200,
            height: 100,
            backgroundColor: 'white',
            border: '1px solid black',
            padding: '10px',
            zIndex: 1000,
          }}
        >
          {modalContent}
        </div>
      )}
    </div>
  );
};

export default MouseOverModal;

import React, { useEffect, useState } from 'react';
import TagInput from '../lib/common/TagInput';
import ToggleText from '../lib/modal/ToggleText';
import TableComponent, { TableColumn, TableData } from '../lib/common/TableComponent';
import { render } from '@testing-library/react';
import Pagination from 'react-js-pagination';
import './Paging.css';
import Dropdown from '../lib/common/DropDown';
import { CiCircleInfo } from "react-icons/ci";
import { FaInfoCircle } from 'react-icons/fa';
import MouseOverModal from '../lib/common/MouseOverModal';
import useRESTAPI from '../lib/hook/RESTAPI';
import { Link, useLocation } from 'react-router-dom';
import { extractPropertyArray, formatDate, formatNumberWithCommas } from '../lib/common/Auxiliary';
import Footer from '../lib/common/Footer';
import CategoryNavigation from '../lib/common/CategoryNavigation';
import SearchBar from '../lib/common/SearchBar';
import ProductItem from '../lib/common/ProductItem';


interface Props {
    searchKeyword?: string;
    handleSearchInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ComparsionPage: React.FC<Props> = (props: Props) => {

    const location = useLocation();
    const { mainCategory, subCategory, subSubCategory } = location.state || {};

    const [mainCategoryState, setMainCategoryState] = useState<number | undefined>();
    const [subCategoryState, setSubCategoryState] = useState<number | undefined>();
    const [subSubCategoryState, setSubSubCategoryState] = useState<number | undefined>();

    const productColumns: TableColumn[] = [
        { key: 'p_code', label: '고유번호', width: 200 },
        { key: 'brand', label: '브랜드명', width: 75 },
        { key: 'p_name', label: '제품명', width: 300 },
        { key: 'category', label: '대분류', width: 75 },
        { key: 'sub_category', label: '중분류', width: 75 },
        { key: 'sub_sub_category', label: '소분류', width: 75 },
        { key: 'p_price', label: '물건 금액', width: 100 },
        { key: 'per_price', label: '개수당 가격', width: 125 },
        { key: 'p_shoppingPay', label: '배송비', width: 50 },
        { key: 'p_total', label: '총 금액', width: 75 },
        { key: 'link', label: '링크', width: 75 },
    ];

    const { fetchItems: fetchKeywords, items: keywords } = useRESTAPI('/api/recommended_keyword');

    const [activePage, setActivePaged] = useState<number>(1);
    const [selectedCategories, setSelectedCategories] = useState<{ main: number, sub: number, subSub: number }>({ main: -1, sub: -1, subSub: -1 });
    const { fetchItems: fetchProducts, pagination: productPagination, items: products } = useRESTAPI('/api/smart_product_comparison');
    const { fetchItems: fetchCategories, items: categories } = useRESTAPI('/api/smart_category');
    /*     const { fetchItems: fetchBrands, items: brands } = useRESTAPI('/api/smart_brand'); */
    const { fetchItems: fetchFilter, items: filter } = useRESTAPI('/api/smart_product_comparison');
    const [params, setParams] = useState<{ [key: string]: any }>({});

    const [searchKeyword, setSearchKeyword] = useState<string>();

    const [init, setInit] = useState<boolean>(false);

    useEffect(() => {
        console.log('ddd', mainCategory, subCategory, subSubCategory)
        setMainCategoryState(mainCategory);
        setSubCategoryState(subCategory);
        setSubSubCategoryState(subSubCategory);
    }, [mainCategory, subCategory, subSubCategory]);


    useEffect(() => {
        console.log('useEffect', mainCategoryState, subCategoryState, subSubCategoryState, searchKeyword);


        if (mainCategory && !mainCategoryState) {
            console.log('ddd2')
            setMainCategoryState(mainCategory);
            setSubCategoryState(subCategory);
            setSubSubCategoryState(subSubCategory);
            setInit(true);
            return
        }


        const _ = async () => {
            await fetchCategories({ pageSize: 8192 })
            await fetchProducts({ page: activePage, pageSize: 10 })
            await fetchKeywords({})
        }

        if (mainCategoryState === undefined && subCategoryState === undefined && subSubCategoryState === undefined) {
            if (searchKeyword) {
                console.log('??')
                refreshResult();
            } else {
                _();
            }
            return;
        }

        const newParams = { ...params };
        let category = -1;

        if (subSubCategoryState) {
            selectedCategories.main = mainCategoryState || -1;
            selectedCategories.sub = subCategoryState || -1;
            selectedCategories.subSub = subSubCategoryState || -1;
            category = subSubCategoryState;
        } else {
            selectedCategories.main = mainCategoryState || -1;
            selectedCategories.sub = subCategoryState || -1;
            selectedCategories.subSub = -1;
            category = subCategoryState || -1;
        }

        if (category !== -1) {
            newParams['p_cuid'] = category;
        }

        if (searchKeyword !== '' && searchKeyword !== undefined) {
            newParams.search = searchKeyword;
        }

        newParams.page = activePage;
        newParams.pageSize = 10;

        const fetchData = async () => {
            await fetchCategories({ pageSize: 8192 });

            if (mainCategoryState || subCategoryState || subSubCategoryState) {
                await fetchProducts(newParams);
                await fetchFilter({ select: 'p_cuid,p_brand,p_name,p_price,per_price,p_shoppingPay', pageSize: 8192 });
            } else {
                await fetchFilter({ select: 'brand,p_name,p_price,per_price,p_shoppingPay', pageSize: 8192 });
                await fetchProducts({ page: 1, pageSize: 10 });
                await fetchKeywords({});
            }
        };

        fetchData();
    }, [mainCategoryState, subCategoryState, subSubCategoryState, searchKeyword, activePage]);

    const refreshResult = async () => {
        console.log('refreshResult', searchKeyword)
        const newParams = { ...params };
        let category = -1;

        // if (selectedCategories.main !== -1) {
        //     category = selectedCategories.main;
        // }

        // if (selectedCategories.sub !== -1) {
        //     category = selectedCategories.sub;
        // }

        // if (selectedCategories.subSub !== -1) {
        //     category = selectedCategories.subSub;
        // }


        setMainCategoryState(undefined);
        setSubCategoryState(undefined);
        setSubSubCategoryState(undefined);


        if (category !== -1) {
            newParams['p_cuid'] = category;
        }

        if (searchKeyword !== '' && searchKeyword !== undefined) {
            newParams.search = searchKeyword;
        }

        newParams.page = activePage;
        newParams.pageSize = 10;
        console.log('newParams', newParams)

        await fetchCategories({ pageSize: 8192 })
        await fetchProducts(newParams)

        await fetchFilter({ select: 'p_cuid,p_brand,p_name,p_price,per_price,p_shoppingPay', pageSize: 8192 })

        /*         fetchFilter({ p_name_like: serchKeyword, select: 'p_brand,p_cuid', pageSize: 8192 }) */
    }

    useEffect(() => {
        if (props.searchKeyword !== '') {
            console.log('의심은 여기쁘ㅜㄴ')
            setSearchKeyword(props.searchKeyword);
            refreshResult();
        } else {
            console.log('의심은 여기쁘ㅜㄴ2')
            setSearchKeyword('');
            refreshResult();
        }
    }, [props.searchKeyword]);

    // useEffect(() => {

    // }, [props.searchKeyword]);

    return (
        <div className='flex flex-col justify-center items-center'>
            <div className='flex justify-between min-w-[375px] max-w-[1280px] h-[100px] w-screen items-center gap-[62px]'>
                <Link to={'/'}><img className=' h-[56px]' src='/Greendea.svg'></img></Link>
                <div className='w-full h-[50px]'>
                    <SearchBar
                        searchKeyword={props.searchKeyword}
                        handleSearchInput={props.handleSearchInputChange}
                        refreshResult={refreshResult}
                    />
                </div>
            </div>

            <CategoryNavigation
                categories={categories}
            />
            <div className='border-b border-[#DFDFDF] w-screen'></div>

            <div className='flex justify-between w-full h-full gap-5 px-5'>
                <div className='pt-14'>
                    <div style={{ backgroundImage: 'url(/api/download/left.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className='pt-14-10 h-[700px] w-[200px] bg-slate-600'></div>
                </div>

                <div className='flex flex-col max-w-[1280px] w-full pt-14'>
                    <ProductItem
                        columns={productColumns}
                        data={products}
                        categories={categories}
                    />
                </div>

                <div className='pt-14'>
                    <div style={{ backgroundImage: 'url(/api/download/right.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className='h-[700px] w-[200px] bg-slate-600'></div>
                </div>

            </div>

            {/* 기존 테이블 */}
            {/* <div className='w-screen max-w-[1440px] overflow-y-scroll  min-w-[375px]'>
                <TableComponent
                    columns={productColumns}
                    data={products}
                    className='pt-10 w-full min-w-[1440px]'
                    renderCell={mainTableCell}
                    renderColumn={mainTableColumn}
                    maxHeight={410}
                /></div> */}

            <div className='flex flex-col max-w-[1280px] w-full pt-14'>
                <ProductItem
                    columns={productColumns}
                    data={products}
                    categories={categories}
                />
            </div>

            <div className='flex w-full max-w-[1280px] justify-end gap-2 pt-8 text-[#909090] font-bold'>
                <Link to={'https://naver.me/xucYFG5g'}>
                    <button className='flex justify-center items-center gap-2 rounded-lg  px-4 py-2'>
                        <MouseOverModal renderContent={() => (<div className=' text-black justify-center items-center whitespace-nowrap'> <p>최신화 요청 후 업데이트</p><p> 반영되면 500 </p><p> 포인트 지급</p></div>)}  >
                            <div className='flex justify-center items-center gap-1 '><p>최신화 요청</p> <FaInfoCircle /> </div>

                        </MouseOverModal>
                    </button>
                </Link>

                <Link to={'https://naver.me/G2xcgnMn'}> <button className='rounded-lg whitespace-nowrap px-4 py-2'>입점 신청</button></Link>
                <Link to={'https://naver.me/5UEH3gYW'}>  <button className='rounded-lg whitespace-nowrap px-4 py-2'>문의하기</button> </Link>
            </div>

            <div className='pt-2'>
                <Pagination
                    activePage={productPagination.page}
                    itemsCountPerPage={productPagination.pageSize}
                    totalItemsCount={productPagination.totalCount}
                    pageRangeDisplayed={5}
                    prevPageText={'‹'}
                    nextPageText={'›'}
                    onChange={setActivePaged}
                />
            </div>

            <div style={{ backgroundImage: 'url(/api/download/bottom.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className='mt-[100px] mb-[120px] w-[1280px] h-[160px] bg-slate-600'></div>

            <Footer />
        </div>
    );
}

export default ComparsionPage;
import React, { useState } from 'react';
import './App.css';
import Footer from './components/lib/common/Footer';
import { AppProvider, useAppContext } from './AppContext';
import { AuthProvider } from './components/lib/provider/AuthContext';

import {
  BrowserRouter,
  Routes,
  Route,
  Router,
  useLocation,
} from 'react-router-dom';

import { ModalProvider } from './components/lib/modal/ModalContext';
import CBCVModalMessageProvider from './components/lib/common/CBCVModalMessageProvider';
import ContentsArea from './components/lib/common/ContentsArea';
import RequireAdminAuth from './components/lib/common/RequireAdminAuth';
import ContentsAreaWithAd from './ContentsAreaWithAd';
import MainPage from './components/pages/MainPage';
import AdminLogin from './components/pages/AdminLogin';
import ProductManagement from './components/pages/ProductManagement';
import ComparsionPage from './components/pages/ComparsionPage';

function App() {

  const [searchKeyword, setSearchKeyword] = useState('');

  const handleSearchInputChange = (event: any) => {
    setSearchKeyword(event.target.value);
  };

  return (
    <AppProvider>
      <ModalProvider>
        <CBCVModalMessageProvider>
          <AuthProvider>
            <BrowserRouter>
              <Routes>
                <Route path='/' element={(<ContentsAreaWithAd><MainPage searchKeyword={searchKeyword} handleSearchInputChange={handleSearchInputChange}></MainPage></ContentsAreaWithAd>)} ></Route>
                <Route path='/adminlogin' element={(<ContentsAreaWithAd><AdminLogin></AdminLogin></ContentsAreaWithAd>)} ></Route>
                <Route path='/productmanagement' element={(<ContentsAreaWithAd><ProductManagement></ProductManagement></ContentsAreaWithAd>)} ></Route>
                <Route path='/comparsion' element={(<ContentsAreaWithAd><ComparsionPage searchKeyword={searchKeyword} handleSearchInputChange={handleSearchInputChange}></ComparsionPage></ContentsAreaWithAd>)} ></Route>
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </CBCVModalMessageProvider>
      </ModalProvider >
    </AppProvider >
  );
}

export default App;

import React, { ReactNode } from 'react';

interface ContentsAreaProps {
    children: ReactNode;
}

const ContentsAreaWithAd: React.FC<ContentsAreaProps> = ({ children }) => {
    return (
        <div className='font-[Pretendard] flex justify-center items-start gap-5'>


            <div style={{ backgroundImage: 'url(/api/download/left.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className='relative top-20 h-[700px] w-[200px]'></div>
            <div className='flex justify-center items-start'>

                <div className='min-h-screen min-w-[375px] w-screen max-w-screen'>
                    {/* <div className='min-h-screen min-w-[375px] w-screen max-w-[1440px]'> */}
                    {children}
                </div>

            </div>
            <div style={{ backgroundImage: 'url(/api/download/right.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className='relative top-20 h-[700px] w-[200px]'></div>
        </div>
    );
};

export default ContentsAreaWithAd;
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

  const InfoItem = ({ label, value }: { label: string; value: string; }) => {
    return (
      <div className='flex gap-3'>
        <span className='text-[#838383]'>{label}</span>
        <span>{value}</span>
      </div>
    );
  }

  return (
    <footer className="w-screen h-[300px] text-sm bg-[#FBFBFB] flex flex-col items-center">
      <div className='w-[1240px] h-full items-center flex flex-col justify-center gap-9 text-[#565656]'>
        <div className='w-full flex justify-between font-bold'>
          <Link to={'https://www.greendea.co.kr/'}>
            <img className='fill-black h-[50px]' src='/Greendea.svg' alt='logo' />
          </Link>
          <div className='h-full items-center flex gap-4'>
            <span>회사소개</span>
            <span>이용안내</span>
            <span>개인정보처리방침</span>
            <span>이용약관</span>
            <span>제휴문의</span>
          </div>
        </div>

        <div className='flex w-full items-center font-medium gap-8'>
          <div className='flex flex-col gap-1'>
            <InfoItem label="대표이사" value="권용성" />
            <InfoItem label="대표전화" value="0000-0000-0000" />
            <InfoItem label="사업자 등록번호" value="203-37-51856" />
          </div>
          <div className='flex flex-col gap-1'>
            <InfoItem label="주소" value="경기도 용인시 수지구 수지로 342번길 18 101동 210호" />
            <InfoItem label="이메일" value="greendea7@gmail.com" />
            <InfoItem label="통신판매업 신고번호" value="2024-용인수지-0459호" />
          </div>
        </div>

        <div className='flex w-full font-medium text-[6E6E6E] justify-between'>
          <span>
            © Greendia Inc. All Rights Reserved.
          </span>
          <div className='flex gap-3'>
            <img className='h-[38px]' src="/logo-b.png" alt="" />
            <img className='h-[38px]' src="/logo-b.png" alt="" />
          </div>
        </div>
      </div>

    </footer>
  );
};

export default Footer;

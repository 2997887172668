import React from 'react';

interface Props {
    items: string[];
    itemClassName: string;
    className: string;
    onItemClick: (index: number, item: string) => void; 
}

const ToggleText: React.FC<Props> = ({ items, className, itemClassName: itemClass, onItemClick }) => {

    return (
        <div className={className}>
            {items.map((item, index) => (
                <div
                    key={index}
                    className={itemClass}
                    onClick={() => onItemClick(index, item)} 
                >
                    {item}
                </div>
            ))}
        </div>
    );
};

export default ToggleText;

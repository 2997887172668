import { useState, useEffect, useCallback } from 'react';
import { json } from 'stream/consumers';
import constant from '../../../constant/constant';

// 개발용, 서버에 등록시 수정
const API_BASE_URL = "http://localhost:3000";

const getAllItems = async (endpoint: string) => {
  const response = await fetch(endpoint, {
    // 개발용, 서버에 등록시 수정
    // credentials: 'include'
  });
  if (!response.ok) {
    throw new Error('Failed to fetch items');
  }
  const data = await response.json();
  return data;
};

const getOneItem = async (endpoint: string, id: number) => {
  const response = await fetch(`${endpoint}/${id}`, {
    // 개발용, 서버에 등록시 수정
    // credentials: 'include'
  });
  if (!response.ok) {
    throw new Error(`Failed to fetch item with id ${id}`);
  }
  const data = await response.json();
  return data;
};

const createItem = async (endpoint: string, data: any) => {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    // 개발용, 서버에 등록시 수정
    // credentials: 'include'
  });
  if (!response.ok) {
    throw new Error('Failed to create item');
  }
  const newItem = await response.json();
  return newItem;
};

const updateItem = async (endpoint: string, id: number, data: any) => {
  data.id = id;
  const response = await fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    // 개발용, 서버에 등록시 수정
    // credentials: 'include'
  });
  if (!response.ok) {
    throw new Error(`Failed to update item with id ${id}`);
  }
  const updatedItem = await response.json();
  return updatedItem;
};

const deleteItem = async (endpoint: string, id: number) => {

  const body = JSON.stringify({ id })


  const response = await fetch(endpoint, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: body,
    // 개발용, 서버에 등록시 수정
    // credentials: 'include'
  });
  const data = await response.json();
  // console.log(data);
  if (!response.ok) {
    throw new Error(`Failed to delete item with id ${id}`);
  }
};

export function buildQueryParams(params: { [key: string]: any }): string {
  const queryParams = new URLSearchParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      queryParams.append(key, params[key]);
    }
  }
  return queryParams.toString();
}

const useRESTAPI = (endpoint: string) => {
  const [items, setItems] = useState<any[]>([]);
  const [hasFetchedSuccessfully, setHasFetchedSuccessfully] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, totalPage: 1, page: 1, totalCount: 0, offset: 0 });

  // 개발용, 서버에 등록시 수정
  // endpoint = API_BASE_URL + endpoint;
  // console.log("endpoint", endpoint);
  // console.log("API_BASE_URL", API_BASE_URL);


  useEffect(() => {


  }, [items]);

  const fetchItems = useCallback(async (query: { [key: string]: any }) => {
    try {
      const queryParams = buildQueryParams(query);

      const data = await getAllItems(endpoint + '?' + queryParams);
      setItems(prevItems => data.data);
      setPagination({
        pageSize: data.pageSize,
        totalPage: data.totalPage,
        page: data.page,
        totalCount: data.totalCount,
        offset: data.offset,
      });

      setHasFetchedSuccessfully(true);

      return data.data;
    } catch (error) {
      console.error(error);
    }
  }, [endpoint]);

  const fetchItem = useCallback(async (id: number) => {
    try {
      const item = await getOneItem(endpoint, id);
      setHasFetchedSuccessfully(true);
      return item;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [endpoint]);

  const addItem = useCallback(async (item: Omit<any, 'id'>) => {
    try {
      const newItem = await createItem(endpoint, item);
      setItems(prevItems => [...prevItems, newItem]);
      setHasFetchedSuccessfully(true);
      return newItem;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [endpoint]);

  const editItem = useCallback(async (id: number, item: Omit<any, 'id'>) => {
    try {
      const updatedItem = await updateItem(endpoint, id, item);
      setItems(prevItems => prevItems.map((i) => (i.id === id ? updatedItem : i)));
      setHasFetchedSuccessfully(true);
      return updatedItem;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [endpoint]);

  const removeItem = useCallback(async (id: number) => {
    try {
      await deleteItem(endpoint, id);
      setItems(prevItems => prevItems.filter((i) => i.id !== id));
      setHasFetchedSuccessfully(true);
    } catch (error) {
      console.error(error);
    }
  }, [endpoint]);
  /* 
    useEffect(() => {
      fetchItems({});
    }, [fetchItems]); */

  return { items, fetchItems, fetchItem, addItem, editItem, removeItem, hasFetchedSuccessfully, pagination };
};

export default useRESTAPI;

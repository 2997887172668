import React, { createContext, useState, useEffect, useContext } from 'react';
import validator from 'email-validator';
import { useNavigate } from 'react-router-dom';
/* import { useModalMessage } from '../common/CBCVModalMessageProvider'; */
interface AuthContextType {
  userid: number | undefined;
  adminid: number | undefined;
  storedAdminIdentity: string | undefined;
  storedAdminPassword: string | undefined;
  storedIdentity: string | undefined;
  storedPassword: string | undefined;
  loginAttempts: number;
  naverSignIn: () => Promise<void>;
  googleSignIn: () => Promise<void>;
  kakaoSignIn: () => Promise<void>;
  setEmailOTP: (email: string, otp: string) => Promise<void>;
  duplicateIdentityCheck: (identity: string) => Promise<boolean>;
  normalSignIn: (
    identity: string,
    password: string,
    autoLogin: boolean,
  ) => Promise<void>;
  adminSignIn: (identity: string, password: string, autoLogin: boolean) => Promise<void>;
  signOut: () => Promise<void>;
  getEmailOTP: (email: string) => Promise<void>;
  findMyID: (identity: string) => Promise<void>;
  newPasswordSession: (identity: string, email: string) => Promise<void>;
  newPassword: (sesstion: string, password: string) => Promise<void>;
  signUp: (signUpData: any) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// 개발용, 서버에 등록시 수정
// const API_BASE_URL = "http://localhost:3000";
const API_BASE_URL = '';

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {


  const [storedAdminIdentity, setStoredAdminIdentity] = useState<string | undefined>(
    localStorage.getItem('adminIdentity') || undefined,
  );
  const [storedAdminPassword, setStoredAdminPassword] = useState<string | undefined>(
    localStorage.getItem('adminPassword') || undefined,
  );

  const [storedIdentity, setStoredIdentity] = useState<string | undefined>(
    localStorage.getItem('identity') || undefined,
  );
  const [storedPassword, setStoredPassword] = useState<string | undefined>(
    localStorage.getItem('password') || undefined,
  );

  const [userid, setUserId] = useState<number>();

  const [loginAttempts, setLoginAttempts] = useState<number>(0);

  const [adminid, setAdmind] = useState<number>();
  const duplicateIdentityCheck = async (identity: string): Promise<boolean> => {
    const response = await fetch(API_BASE_URL + '/api/auth/?identity=' + identity, {
      method: 'HEAD',
    });
    return response.status === 200;
  };

  useEffect(() => {
    console.log(loginAttempts);
  }, [loginAttempts]);

  const updateLoginAttempts = async (identity: string) => {

    try {
      const response = await fetch(API_BASE_URL + '/api/auth/login-attempts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          identity,
        }),
      });



      const data = await response.json();
      if (response.ok) {

        console.log(data);

        setLoginAttempts(data.loginattempts)
      }
      else {
        console.log(data);
      }
    } catch (error) {


    }
  };

  const CheckSession = async () => {
    try {
      const response = await fetch(API_BASE_URL + '/api/auth/readSession');

      if (response.ok) {
        const data = await response.json();
        console.log('set id');
        setUserId(data.id);
      } else {
        throw new Error('readSession failed');
      }
    } catch (error) {
      console.error('Failed to check session:', error);
    }
  };

  const CheckAdminSession = async () => {
    try {
      const response = await fetch(API_BASE_URL + '/api/auth/readadminsession');

      if (response.ok) {
        const data = await response.json();
        setAdmind(data.id);
      }
    } catch (error) { }
  };

  useEffect(() => {

    console.log('CheckSession');
    const initializeSessions = async () => {

      await CheckSession();
      await CheckAdminSession();
    };
    initializeSessions();
  }, []);

  useEffect(() => {
    console.log(userid);
  }, [userid]);

  const getEmailOTP = async (email: string) => {
    if (!validator.validate(email)) {
      throw new Error('Invalid email format');
    }

    const response = await fetch(
      API_BASE_URL + '/api/auth/email-verification?email=' + email,
      {
        method: 'GET',
      },
    );
  };

  const setEmailOTP = async (email: string, otp: string) => {
    const response = await fetch(API_BASE_URL + '/api/auth/email-confirm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        otp,
      }),
    });
    console.log(response);
    if (!response.ok) {
      throw new Error('Invalid email otp');
    }
  };

  const normalSignIn = async (
    identity: string,
    password: string,
    autoLogin: boolean,
  ) => {
    try {
      const response = await fetch(API_BASE_URL + '/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          identity,
          password,
        }),
      });

      if (!response.ok) {
        await updateLoginAttempts(identity);
        throw new Error(response.status.toString());
      }

      const data = await response.json();

      if (autoLogin) {
        localStorage.setItem('identity', identity);
        localStorage.setItem('password', password);
      } else {
        localStorage.removeItem('identity');
        localStorage.removeItem('password');
      }
      setUserId(data.id);
    } catch (error) {
      throw error;
    }


  };

  const adminSignIn = async (identity: string, password: string, adminAutoLogin: boolean) => {
    const response = await fetch(API_BASE_URL + '/api/auth/admin-login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        identity,
        password,
      }),
    });

    if (adminAutoLogin) {
      localStorage.setItem('adminIdentity', identity);
      localStorage.setItem('adminPassword', password);
    } else {
      localStorage.removeItem('adminIdentity');
      localStorage.removeItem('adminPassword');
    }

    const data = await response.json();
    setAdmind(data.id);
  };

  const naverSignIn = async () => {
    window.location.href = '../api/auth/naver/login';
  };

  const googleSignIn = async () => {
    window.location.href = '../api/google/login';
  };

  const kakaoSignIn = async () => {
    window.location.href = '../api/auth/kakao/login';
  };

  const signOut = async () => {
    localStorage.removeItem('identity');
    localStorage.removeItem('password');

    const response = await fetch(API_BASE_URL + '/api/auth/signout', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  const findMyID = async (email: string) => {
    const response = await fetch(API_BASE_URL + '/api/auth/findmyid', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Error: ${response.status}, ${errorData.result}`);
    }

    const result = await response.json();
    /*       updateMessage('confirm', '입력하신 메일 주소로 아이디를 발송했어요!') */
    window.location.href = '/login';
  };

  const newPassword = async (session: string, password: string) => {
    const response = await fetch(API_BASE_URL + '/api/auth/newpassword/' + session, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ newpassword: password }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.log(errorData);
      throw new Error(`Error: ${response.status}, ${errorData.result}`);
    }

    const result = await response.json();
    window.location.href = '/';
  };

  const newPasswordSession = async (identity: string, email: string) => {
    const response = await fetch(API_BASE_URL + '/api/auth/newPasswordsession', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, identity }),
    });

    if (response.status !== 200) {
      const errorData = await response.json();
      throw new Error(`Error: ${response.status}, ${errorData.result}`);
    }

    const result = await response.json();

    window.location.href = '/login';
  };

  const signUp = async (signUpData: any) => {
    const url = '/api/user/';

    const response = await fetch(API_BASE_URL + url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(signUpData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Error: ${response.status}, ${errorData.result}`);
    }

    const result = await response.json();
  };

  return (
    <AuthContext.Provider
      value={{
        storedIdentity,
        storedPassword,
        storedAdminIdentity,
        storedAdminPassword,
        userid,
        adminid,
        loginAttempts,
        getEmailOTP,
        newPasswordSession,
        newPassword,
        findMyID,
        setEmailOTP,
        duplicateIdentityCheck,
        normalSignIn,
        adminSignIn,
        naverSignIn,
        googleSignIn,
        kakaoSignIn,
        signOut,
        signUp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export interface ProductColumn {
    key: string;
    label: string;
}

export interface ProductData {
    [key: string]: any;
}

export interface ProductItemProps {
    columns: ProductColumn[];
    data: ProductData[];
    categories: any[];
}

const ProductItem: React.FC<ProductItemProps> = ({
    data,
    categories
}) => {
    const navigate = useNavigate();

    const handleLink = (link: string) => {
        window.open(link, '_blank'); // 새 탭으로 링크 열기
    }

    // 숫자를 천 단위로 구분하는 함수
    const formatNumberWithCommas = (num: number) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const getCategoryNames = (cuid: number) => {
        const categoryNames: string[] = [];
        const category = categories.find(category => category.c_uid === cuid);

        if (!category) return categoryNames;

        // 대분류
        if (category.c_depth === 1) {
            categoryNames.push(category.c_name);
        } else if (category.c_depth === 2) {
            const parent = categories.find(cat => cat.c_uid === Number.parseInt(category.c_parent));
            if (parent) {
                categoryNames.push(parent.c_name);
                categoryNames.push(category.c_name);
            }
        } else if (category.c_depth === 3) {
            const parent = categories.find(cat => cat.c_uid === Number.parseInt(category.c_parent.split(',')[0]));
            if (parent) {
                categoryNames.push(parent.c_name);
                const subParent = categories.find(cat => cat.c_uid === Number.parseInt(category.c_parent.split(',')[1]));
                if (subParent) {
                    categoryNames.push(subParent.c_name);
                }
                categoryNames.push(category.c_name);
            }
        }

        return categoryNames;
    };

    return (
        <div className="w-full">
            {data.map((rowData, rowIndex) => {
                const categoryNames = getCategoryNames(Number.parseInt(rowData['p_cuid']));

                return (
                    <div key={rowIndex} className="w-full border-b border-[#E1E1E1] cursor-pointer" onClick={() => handleLink(rowData.link)}>
                        <div className="flex p-3 gap-4">
                            <img className='h-[148px] w-[233px]' src={rowData.imageSrc || "/Greendea.svg"} alt="" />
                            <div className="flex flex-col w-full p-3 font-medium gap-2">
                                <span className="text-sm">제품번호 : {rowData.p_code}</span>
                                <span className="text-2xl font-semibold">{rowData.p_name}</span>
                                <div className="flex gap-6">
                                    <span className="text-base">대분류 {`>`} {categoryNames[0] || '-'}</span>
                                    <span className="text-base">중분류 {`>`} {categoryNames[1] || '-'}</span>
                                    <span className="text-base">소분류 {`>`} {categoryNames[2] || '-'}</span>
                                </div>
                                <div className="w-full flex justify-end gap-5 text-base">
                                    <span>가격 : {formatNumberWithCommas(rowData.p_price)}원</span>
                                    <span>배송비 : {formatNumberWithCommas(rowData.p_shoppingPay)}원</span>
                                    <span className="text-xl">총금액 : {formatNumberWithCommas(rowData.p_price + rowData.p_shoppingPay)}원</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ProductItem;

import React, { useEffect, useState } from 'react';
import TagInput from '../lib/common/TagInput';
import ToggleText from '../lib/modal/ToggleText';
import TableComponent, { TableColumn, TableData } from '../lib/common/TableComponent';
import { render } from '@testing-library/react';
import Pagination from 'react-js-pagination';
import './Paging.css';
import Dropdown from '../lib/common/DropDown';
import { CiCircleInfo } from "react-icons/ci";
import { FaInfoCircle } from 'react-icons/fa';
import MouseOverModal from '../lib/common/MouseOverModal';
import useRESTAPI from '../lib/hook/RESTAPI';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import { extractPropertyArray, formatDate, formatNumberWithCommas } from '../lib/common/Auxiliary';
import CategoryNavigation from '../lib/common/CategoryNavigation';
import Footer from '../lib/common/Footer';
import SearchBar from '../lib/common/SearchBar';

interface Props {
  searchKeyword?: string;
  handleSearchInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

interface KeyValue {
  key: string;
  value: string;
}

const MainPage: React.FC<Props> = (props: Props) => {

  const navigate = useNavigate();

  const productColumns: TableColumn[] = [
    { key: 'p_code', label: '고유번호', width: 200 },
    { key: 'brand', label: '브랜드명', width: 75 },
    { key: 'p_name', label: '제품명', width: 300 },
    { key: 'category', label: '대분류', width: 75 },
    { key: 'sub_category', label: '중분류', width: 75 },
    { key: 'sub_sub_category', label: '소분류', width: 75 },
    { key: 'p_price', label: '물건 금액', width: 100 },
    { key: 'per_price', label: '개수당 가격', width: 125 },
    { key: 'p_shoppingPay', label: '배송비', width: 50 },
    { key: 'p_total', label: '총 금액', width: 75 },
    { key: 'link', label: '링크', width: 75 },
  ];

  const { fetchItems: fetchKeywords, items: keywords } = useRESTAPI('/api/recommended_keyword');

  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [activePage, setActivePaged] = useState<number>(1);
  const [selectedCategories, setSelectedCategories] = useState<{ main: number, sub: number, subSub: number }>({ main: -1, sub: -1, subSub: -1 });
  const { fetchItems: fetchProducts, pagination: productPagination, items: products } = useRESTAPI('/api/smart_product_comparison');
  const { fetchItems: fetchCategories, items: categories } = useRESTAPI('/api/smart_category');
  /*     const { fetchItems: fetchBrands, items: brands } = useRESTAPI('/api/smart_brand'); */
  const { fetchItems: fetchFilter, items: filter } = useRESTAPI('/api/smart_product_comparison');
  const [params, setParams] = useState<{ [key: string]: any }>({});


  const mainCell = (rowIndex: number, rowData: TableData, column: TableColumn, columnIndex: number) => {

    const _ = (c_uid: number) => {
      if (selectedCategories.main === c_uid) {
        setSelectedCategories(prevState => ({
          ...prevState,
          main: -1,
          sub: -1,
          subSub: -1
        }));
      }
      else {
        setSelectedCategories(prevState => ({
          ...prevState,
          main: c_uid
        }));
      }
    }
    const depth1 = categories.filter(item => item.c_depth === 1)
    const selected = selectedCategories.main === depth1[rowIndex].c_uid;

    return (
      <div onClick={() => _(depth1[rowIndex].c_uid)} className={`qcursor-pointer font-bold flex justify-center items-center h-10  border-gray-200 ${selected ? 'bg-gray-300' : ''} `}>
        {rowData[column.key]}
      </div>
    );
  };

  const mainTableCell = (rowIndex: number, rowData: TableData, column: TableColumn, columnIndex: number) => {

    let border = 'font-bold flex justify-center items-center '

    if (rowIndex === 0) {
      /* border +=  'border-t-[1px] ' */
    }
    else {

      border += 'border-t-[1px] border-b-[1px] '
    }

    if (columnIndex === 0) {
      border += 'border-r-[1px] border-l-[1px] '
    }
    else {
      border += 'border-r-[1px] '
    }


    let text = rowData[column.key];


    if (column.key === 'brand') {
      if (rowData[column.key])
        text = rowData[column.key].c_name
    }

    if (column.key === 'category' && categories.length > 0) {
      const obj = categories.find(category => category.c_uid === Number.parseInt(rowData['p_cuid']))

      if (obj && obj.c_depth === 1) {
        text = obj.c_name;
      }
      if (obj && obj.c_depth === 2) {

        text = categories.find(category => category.c_uid === Number.parseInt(obj.c_parent)).c_name;
      }
      if (obj && obj.c_depth === 3) {
        text = categories.find(category => category.c_uid === Number.parseInt(obj.c_parent.split(',')[0])).c_name;
      }
    }
    if (column.key === 'sub_category' && categories.length > 0) {
      const obj = categories.find(category => category.c_uid === Number.parseInt(rowData['p_cuid']))

      if (obj && obj.c_depth === 2) {
        text = obj.c_name;
      }
      if (obj && obj.c_depth === 3) {
        text = categories.find(category => category.c_uid === Number.parseInt(obj.c_parent.split(',')[1])).c_name;

      }
    }
    if (column.key === 'sub_sub_category' && categories.length > 0) {
      const obj = categories.find(category => category.c_uid === Number.parseInt(rowData['p_cuid']))

      if (obj && obj.c_depth === 3) {
        text = obj.c_name
      }
    }
    if (column.key === 'p_price') {
      text = formatNumberWithCommas(rowData['p_price'])
    }
    if (column.key === 'per_price') {
      text = formatNumberWithCommas(rowData['per_price'])
    }

    if (column.key === 'p_total') {
      text = formatNumberWithCommas(rowData['p_price'] + rowData['p_shoppingPay'])
    }

    if (column.key === 'link') {


      return (
        <div className={`${border} h-10 font-normal underline text-blue-700 border-gray-200`}>
          {
            rowData['link'] &&
            <Link target="_blank" rel="noopener noreferrer" to={rowData['link']} >
              {'링크'}
            </Link>
          }
        </div>
      );


    };


    return (
      <div className={`${border} h-10 border-gray-200`}>
        {text}
      </div>
    );
  };



  const subCell = (rowIndex: number, rowData: TableData, column: TableColumn, columnIndex: number) => {

    const _ = (c_uid: number) => {
      if (selectedCategories.sub === c_uid) {
        setSelectedCategories(prevState => ({
          ...prevState,
          sub: -1,
          subSub: -1
        }));
      }
      else {
        setSelectedCategories(prevState => ({
          ...prevState,
          sub: c_uid
        }));
      }
    }
    const depth2 = categories.filter(item => item.c_depth === 2 && Number.parseInt(item.c_parent) === selectedCategories.main)
    const selected = selectedCategories.sub === depth2[rowIndex].c_uid;
    return (
      <div onClick={() => _(depth2[rowIndex].c_uid)} className={`cursor-pointer font-bold flex justify-center items-center h-10  border-gray-200 ${selected ? 'bg-gray-300' : ''}`}>
        {rowData[column.key]}
      </div>
    );
  };


  const handleDropdown = async (value: string, key: string) => {

    if (value === 'none') {
      const { [key]: _, ...rest } = params;
      setParams(rest);
    }
    else {
      setParams(prevParams => ({
        ...prevParams,
        [key]: value
      }));

    }
  };

  const column = (column: TableColumn) => {
    return (
      <div className='font-bold py-3 px-1 text-white flex justify-center items-center bg-green-500 rounded-tl-lg'>
        {column.label}
      </div>
    );
  };

  const subColumn = (column: TableColumn) => {
    return (
      <div className='font-bold py-3 px-1 text-white flex justify-center bg-green-500'>
        {column.label}
      </div>
    );
  };

  const mainTableColumn = (column: TableColumn, index: number) => {

    let border = 'border-b-[1px] ';

    if (index === 0) {
      border += 'rounded-tl-2xl border-l-[1px] border-t-[1px]'
    }
    else if (index === 10) {
      border += 'rounded-tr-2xl border-l-[1px] border-r-[1px] border-t-[1px]'
    }
    else {
      border += 'border-l-[1px] border-t-[1px]'
    }

    if (column.key === 'p_brand' ||
      column.key === 'p_name' ||
      column.key === 'p_price' ||
      column.key === 'per_price' ||
      column.key === 'p_shoppingPay' ||
      column.key === 'brand' ||
      column.key === 'p_total'
    ) {

      const options = [
        {
          key: 'none',
          label: column.label,
        },
      ];


      if (column.key === 'brand') {
        const a = filter.filter(item => item.brand !== null);
        // console.log(a);
        const data = Array.from(new Set(a.map(f => f.brand.c_name)));
        // console.log(data);
        for (let i = 0; i < data.length; i++) {

          options.push({
            key: data[i],
            label: data[i]
          })

        }
      }
      else if (column.key === 'p_name') {
        const data = Array.from(new Set(filter.map(f => f.p_name)));
        for (let i = 0; i < data.length; i++) {
          options.push({
            key: data[i],
            label: data[i]
          })

        }
      }

      else if (column.key === 'p_price') {
        const data = Array.from(new Set(filter.map(f => f.p_price)));
        for (let i = 0; i < data.length; i++) {
          options.push({
            key: data[i],
            label: formatNumberWithCommas(data[i])
          })

        }
      }
      else if (column.key === 'per_price') {
        const data = Array.from(new Set(filter.map(f => f.per_price)));
        for (let i = 0; i < data.length; i++) {
          options.push({
            key: data[i],
            label: formatNumberWithCommas(data[i])
          })

        }
      }
      else if (column.key === 'p_shoppingPay') {
        const data = Array.from(new Set(filter.map(f => f.p_shoppingPay)));
        for (let i = 0; i < data.length; i++) {
          options.push({
            key: data[i],
            label: formatNumberWithCommas(data[i])
          })

        }
      }
      else if (column.key === 'p_total') {
        const data = Array.from(new Set(filter.map(f => f.p_shoppingPay + f.p_price)));
        for (let i = 0; i < data.length; i++) {
          options.push({
            key: data[i],
            label: formatNumberWithCommas(data[i])
          })

        }
      }
      // console.log(options);



      return (
        <div className={`${border}  flex justify-center items-center h-10`}>
          <Dropdown
            onOptionChange={(value) => handleDropdown(value, column.key)}
            selectedOptionKey={'none'}
            options={options}
            buttonClassName="font-bold flex justify-between items-center rounded-lg  "
            dropdownClassName="max-h-60 overflow-y-auto font-bold flex flex-col border-[1px] border-[#D8DDE3]    flex justify-between items-start rounded-lg bg-white "
            itemClassName="font-bold px-4 w-full h-[30px]"
            itemSelectedClassName="font-bold px-4 h-[30px] w-full  "
            itemHoverClassName="font-bold px-4 h-[30px] w-full "
          />
        </div>
      );
    }
    else {
      return (
        <div className={`${border}  flex justify-center items-center h-10`}>
          {column.label}
        </div>
      );
    }
    //{column.label}

  };



  useEffect(() => {
    const _ = async () => {
      await fetchFilter({ select: 'brand,p_name,p_price,per_price,p_shoppingPay', pageSize: 8192 })
      /*             await fetchBrands({ pageSize: 8192 }) */
      await fetchCategories({ pageSize: 8192 })
      await fetchProducts({ page: 1, pageSize: 10 })
      await fetchKeywords({})
      //,p_cuid

    }
    _();
  }, []);

  useEffect(() => {

  }, [products, categories, filter, keywords]);


  useEffect(() => {
    fetchProducts({ page: activePage, pageSize: 10 })
  }, [activePage]);

  useEffect(() => {

    refreshResult();
  }, [selectedCategories, params]);


  const handleToggleText = (index: number, item: string) => {
    setSearchKeyword(item);
    refreshResult();
  }
  const refreshResult = async () => {

    const newParams = { ...params };
    let category = -1;



    if (selectedCategories.main !== -1) {
      category = selectedCategories.main;
    }

    if (selectedCategories.sub !== -1) {
      category = selectedCategories.sub;
    }

    if (selectedCategories.subSub !== -1) {
      category = selectedCategories.subSub;
    }

    if (category !== -1) {
      newParams['p_cuid'] = category;
    }
    if (searchKeyword !== '' && searchKeyword !== undefined) {
      newParams.search = searchKeyword;
    }
    newParams.page = activePage;
    newParams.pageSize = 10;

    // console.log(newParams);
    await fetchCategories({ pageSize: 8192 })
    await fetchProducts(newParams)


    await fetchFilter({ select: 'p_cuid,p_brand,p_name,p_price,per_price,p_shoppingPay', pageSize: 8192 })

    /*         fetchFilter({ p_name_like: serchKeyword, select: 'p_brand,p_cuid', pageSize: 8192 }) */
  }

  const handleSearch = () => {
    navigate('/comparsion');
  }

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  }

  const subsubColumn = (column: TableColumn) => {
    return (
      <div className=' font-bold py-3 px-1 text-white flex justify-center bg-green-500 rounded-tr-lg'>
        {column.label}
      </div>
    );
  };
  const subSubCell = (rowIndex: number, rowData: TableData, column: TableColumn, columnIndex: number) => {

    const _ = (c_uid: number) => {
      if (selectedCategories.subSub === c_uid) {
        setSelectedCategories(prevState => ({
          ...prevState,
          subSub: -1
        }));
      }
      else {
        setSelectedCategories(prevState => ({
          ...prevState,
          subSub: c_uid
        }));
      }
    }
    const depth3 = categories.filter(item => item.c_depth === 3 && Number.parseInt(item.c_parent.split(',')[1]) === selectedCategories.sub)
    const selected = selectedCategories.subSub === depth3[rowIndex].c_uid;
    return (
      <div onClick={() => _(depth3[rowIndex].c_uid)} className={`cursor-pointer font-bold flex justify-center items-center h-10  border-gray-200  ${selected ? 'bg-gray-300' : ''}`}>
        {rowData[column.key]}
      </div>
    );
  };

  return (
    <div className='flex flex-col justify-center items-center'>

      <div className='flex flex-col max-w-screen min-w-[375px] w-screen items-center'>
        <div className='w-full flex flex-col items-center'>
          <div className='flex h-[100px] w-full border-b border-[#F7F7F7] align-middle items-center justify-center'>
            <Link to={'https://www.greendea.co.kr/'}><img className=' h-[45px]' src='/Greendea.svg' alt='logo'></img></Link>
          </div>

          <CategoryNavigation
            categories={categories}
          />
        </div>
        <div className='border-b border-[#DFDFDF] w-screen'></div>
      </div>

      <div className='flex flex-col justify-center items-center pb-72'>
        <div className='flex flex-col w-full pt-[50px] max-w-[827px]'>

          <div className='flex mx-auto items-center'>
            <Link to={'https://www.greendea.co.kr/'}><img className=' h-[112px]' src='/Greendea.svg' alt='logo'></img></Link>
          </div>
          <div className='flex h-[58px]'>
            {/* <input
            className=' bg-gray-200  w-full rounded-lg px-4 py-2'
            type='text'
            value={serchKeyword}
            onChange={handleSearchInputChange}
          />
          <button onClick={refreshResult} className=' bg-green-500  rounded-lg whitespace-nowrap px-4 py-2 text-white font-bold '>검색 </button> */}
            <SearchBar
              searchKeyword={searchKeyword}
              handleSearchInput={props.handleSearchInputChange}
              refreshResult={handleSearch}
            />
          </div>
          <div className='w-full min-w-[375px] pt-5 flex items-center justify-center gap-2'>
            <div className='font-bold text-sm whitespace-nowrap text-[#727272]'>추천 검색어: </div>
            <ToggleText
              onItemClick={handleToggleText} items={extractPropertyArray(keywords, 'keyword')}
              className='flex gap-2 text-[#898989]'
              itemClassName='cursor-pointer bg-[#F0F0F0] rounded-full px-4 py-2 text-sm'></ToggleText>
          </div>
        </div>
        <div style={{ backgroundImage: 'url(/api/download/bottom.png)', backgroundSize: 'cover', backgroundPosition: 'center' }} className='my-20 w-[1280px] h-[200px] bg-[#F0F0F0]'></div>
      </div>

      <Footer />
    </div>
  );
};

export default MainPage;
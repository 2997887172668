import React from 'react';
import { IoMdAdd } from 'react-icons/io';

export interface TableColumn {
  key: string;
  label: string;
  width?: number;
}
export interface TableData {
  [key: string]: any;
}
export interface TableComponentProps {
  columns: TableColumn[];
  data: TableData[];
  className: string;
  renderCell: (
    rowIndex: number,
    rowData: TableData,
    column: TableColumn,
    columnIndex: number,
  ) => React.ReactNode;
  maxHeight?: number;
  renderColumn: (column: TableColumn, colnumber: number) => React.ReactNode;
  onAddNewRow?: (type: string) => void; // 새로운 행을 추가하는 함수
}

const TableComponent: React.FC<TableComponentProps> = ({
  columns,
  data,
  className,
  maxHeight,
  renderCell,
  renderColumn,
  onAddNewRow,
}) => {
  // console.log('투피스', \\)
  return (
    <div className={className}>
      <div className="flex w-full justify-start bg-[#F8F8F8] border-b border-[#F8F8F8]">
        {columns.map((column, columnIndex) => (
          <div
            key={column.key}
            style={{ width: '100%', minWidth: column.width }}
          >
            {renderColumn(column, columnIndex)}
          </div>
        ))}
      </div>
      <div
        style={{ height: maxHeight, overflowX: 'clip', overflowY: 'auto' }}>
        {data.map((rowData, rowIndex) => (
          <div
            key={rowIndex}
            className={`flex justify-start`}
          >
            {columns.map((column, columnIndex) => {
              // console.log('column', column);
              // console.log('rowData', rowData);
              return (
                <div
                  key={column.key}
                  style={{ width: '100%', minWidth: column.width }}
                  className=''
                >
                  {renderCell(rowIndex, rowData, column, columnIndex)}
                </div>
              );
            })}
          </div>
        ))}
        {/* 새로운 행 추가 버튼 */}
        {onAddNewRow && data.length >= 0 &&
          (
            <div className="flex justify-start h-10 cursor-pointer">
              <IoMdAdd className='w-full h-full text-gray-500' onClick={() => onAddNewRow('')} />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default TableComponent;

// src/components/ProductManagement.tsx

import React, { useEffect, useState } from 'react';
import TableComponent, { TableColumn, TableData } from '../lib/common/TableComponent';
import Dropdown from '../lib/common/DropDown';
import FileUploadButton from '../lib/common/FileUploadButton';
import TagInput from '../lib/common/TagInput';
import Pagination from 'react-js-pagination';
import CategoryNavigation from '../lib/common/CategoryNavigation';
import { IoMdAddCircle, IoMdAdd } from "react-icons/io";
import { Link } from 'react-router-dom';
import Footer from '../lib/common/Footer';
import SearchBar from '../lib/common/SearchBar';

import ModalBase from '../lib/common/ModalBase';
import useRESTAPI from '../lib/hook/RESTAPI';
import * as XLSX from 'xlsx';


import { MdDelete } from 'react-icons/md';


function generateKey(): string {
  function generatePart(): string {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';

    const letter = letters.charAt(Math.floor(Math.random() * letters.length));

    const number = Array.from({ length: 4 }, () => numbers.charAt(Math.floor(Math.random() * numbers.length))).join('');

    return `${letter}${number}`;
  }

  const part1 = generatePart();
  const part2 = generatePart();
  const part3 = generatePart();

  const key = `${part1}-${part2}-${part3}`;
  return key;
}


interface SmartProduct {
  p_code: string;
  p_cuid?: number;
  p_cpid: string;
  p_stock: number;
  p_salecnt: number;
  p_price: number;
  p_point_per: number;
  p_name: string;
  p_subname: string;
  p_view: 'Y' | 'N';
  p_option_valid_chk: 'Y' | 'N';
  p_newview: 'Y' | 'N';
  p_saleview: 'Y' | 'N';
  p_bestview: 'Y' | 'N';
  p_content?: string;
  p_content_m: string;
  p_rdate?: Date;
  p_img_list?: string;
  p_img_list_over?: string;
  p_img_b1?: string;
  p_img_b2?: string;
  p_img_b3?: string;
  p_img_b4?: string;
  p_img_b5?: string;
  p_img_list2: string;
  p_img_list_square: string;
  p_coupon?: string;
  p_idx: number;
  p_option_type_chk: 'nooption' | '1depth' | '2depth' | '3depth';
  p_shorten_url?: string;
  p_orgin: string;
  p_maker: string;
  p_shoppingPay_use: 'Y' | 'N' | 'F' | 'P';
  p_shoppingPay: number;
  p_shoppingPayFree: number;
  p_shoppingPayPdPrice: number;
  p_shoppingPayPfPrice: number;
  p_commission_type: string;
  p_sPersent: number;
  p_sPrice: number;
  p_icon: string;
  p_screenPrice: number;
  p_delivery_info: string;
  p_relation: string;
  p_relation_type: 'none' | 'category' | 'manual';
  p_naver_switch: 'Y' | 'N';
  p_daum_switch: 'Y' | 'N';
  p_mdate?: Date;
  p_hashtag: string;
  p_hashtag_shuffle: 'Y' | 'N';
  npay_use: 'Y' | 'N';
  p_vat: 'Y' | 'N';
  p_sort_group: number;
  p_sort_idx: number;
  p_use_content: 'Y' | 'N';
  p_brand: number;
  p_groupset_use: 'Y' | 'N';
  p_free_delivery_event_use: 'Y' | 'N';
  p_option1_type: 'normal' | 'size' | 'color';
  p_option2_type: 'normal' | 'size' | 'color';
  p_option3_type: 'normal' | 'size' | 'color';
  p_soldout_chk: 'Y' | 'N';
  p_option1_title: string;
  p_option2_title: string;
  p_option3_title: string;
  p_sale_type: 'T' | 'A';
  p_sale_sdate?: Date;
  p_sale_edate?: Date;
  p_sale_sdateh: number;
  p_sale_sdatem: number;
  p_sale_edateh: number;
  p_sale_edatem: number;
  p_expire_type: '' | 'day' | 'date';
  p_expire_day: number;
  p_expire_date?: Date;
  p_type: 'delivery' | 'ticket';
  p_dateoption_use: 'Y' | 'N';
  p_dateoption_date_type: 'day' | 'date';
  p_dateoption_sday: number;
  p_dateoption_eday: number;
  p_dateoption_sdate?: Date;
  p_dateoption_edate?: Date;
  p_dateoption_stime?: string;
  p_dateoption_etime?: string;
  p_dateoption_ex_week?: string[];
  p_dateoption_ex_date?: string;
  p_buy_limit?: number;
  p_duplicate_use: 'Y' | 'N';
  p_com_juso: string;
  p_com_mapx: string;
  p_com_mapy: string;
  p_apply_mapxy: 'Y' | 'N';
  p_com_tel?: string;
  p_com_locname?: string;
  p_time_sale: 'Y' | 'N';
  p_time_sale_sdate?: Date;
  p_time_sale_sclock?: string;
  p_time_sale_edate?: Date;
  p_time_sale_eclock?: string;
  p_com_time?: string;
  per_price?: number;
  link: string;
}

type RowData = { [key: string]: any };
const ProductManagement: React.FC = () => {

  const productColumns: TableColumn[] = [
    { key: 'p_code', label: '고유번호', width: 177 },
    { key: 'brand', label: '브랜드명', width: 108 },
    { key: 'p_name', label: '제품명', width: 224 },
    { key: 'category', label: '대분류', width: 87 },
    { key: 'sub_category', label: '중분류', width: 87 },
    { key: 'sub_sub_category', label: '소분류', width: 87 },
    { key: 'p_price', label: '물건 금액', width: 92 },
    { key: 'per_price', label: '개수당 가격', width: 92 },
    { key: 'p_shoppingPay', label: '배송비', width: 92 },
    { key: 'p_total', label: '총 금액', width: 85 },
    { key: 'link', label: '링크', width: 90 },
    { key: 'delete', label: '삭제', width: 58 },
  ];

  const { fetchItems: fetchKeywords, items: keywords, removeItem: removeKeyword, addItem: addKeyword } = useRESTAPI('/api/recommended_keyword');

  const { fetchItems: fetchBrands, addItem: addBrand } = useRESTAPI('/api/smart_brand');


  const {
    fetchItems: fetchProducts,
    pagination: productPagination,
    items: products,
    addItem: addProdcut,

  } = useRESTAPI('/api/smart_product_comparison');



  const { fetchItems: fetchCategories, items: categories, addItem: addCategory, removeItem: removeCategory } = useRESTAPI('/api/smart_category');
  const { fetchItems: fetchFilter, items: filter, } = useRESTAPI('/api/smart_product_comparison');
  const [selectedCategories, setSelectedCategories] = useState<{ main: number, sub: number, subSub: number }>({ main: -1, sub: -1, subSub: -1 });
  const [categoryWillAdd, setCategoryWillAdd] = useState<{ main: number, sub: number, subSub: number }>({ main: -1, sub: -1, subSub: -1 });
  const [params, setParams] = useState<{ [key: string]: any }>({});
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleAd, setIsVisibleAd] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<string>();

  const [activePage, setActivePaged] = useState<number>(1);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [xlsxRows, setXLSXRow] = useState<RowData[] | null>(null);
  interface Product {
    p_brand: string;
    p_name: string;
    p_cuid: number;
    p_price: number;
    per_price: number;
    p_shoppingPay: number;
    link: string;
  }
  const [rowWillAdd, setRowWillAdd] = useState<Product>({
    p_brand: '',
    p_name: '',
    p_cuid: -1,
    p_price: -1,
    per_price: -1,
    p_shoppingPay: -1,
    link: "",
  });

  const [top, setTop] = useState<File>();
  const [bottom, setBottom] = useState<File>();
  const [left, setLeft] = useState<File>();

  const [showCModal, setShowCModal] = useState(false);
  const [currentType, setCurrentType] = useState<string>('');

  interface CNameModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (name: string) => void;
  }

  const CNameModal: React.FC<CNameModalProps> = ({
    isOpen,
    onClose,
    onConfirm
  }) => {
    const [cName, setCName] = useState('');

    if (!isOpen) return null;

    const handleConfirm = () => {
      onConfirm(cName); // 모달 확인 시 cName 전달
      setCName(''); // 입력 초기화
      onClose(); // 모달 닫기
    };

    return (
      <ModalBase>
        <div className="modal bg-white rounded-lg p-4">
          <div className="modal-content flex flex-col gap-4">
            <span className='text-base font-semibold'>카테고리 이름 입력</span>
            <input
              type="text"
              value={cName}
              onChange={(e) => setCName(e.target.value)}
              placeholder="새로운 카테고리"
              className='border border-gray-300 rounded-lg text-sm p-2'
            />
            <div className='flex justify-end gap-2'>
              <button className='bg-green-500  rounded-lg whitespace-nowrap px-4 py-2 text-white font-bold' onClick={handleConfirm}>추가</button>
              <button className='bg-gray-500 rounded-lg whitespace-nowrap px-4 py-2 text-white font-bold' onClick={onClose}>취소</button>
            </div>

          </div>
        </div>
      </ModalBase>
    );
  };

  const drawModal = () => {
    const column = (column: TableColumn) => {
      return (
        <div className='font-bold py-3 px-1 text-white flex justify-center items-center bg-green-500 rounded-tl-lg'>
          {column.label}
        </div>
      );
    };

    const subColumn = (column: TableColumn) => {
      return (
        <div className='font-bold py-3 px-1 text-white flex justify-center bg-green-500'>
          {column.label}
        </div>
      );
    };

    const subsubColumn = (column: TableColumn) => {
      return (
        <div className=' font-bold py-3 px-1 text-white flex justify-center bg-green-500 rounded-tr-lg'>
          {column.label}
        </div>
      );
    };

    const mainCell = (rowIndex: number, rowData: TableData, column: TableColumn, columnIndex: number) => {

      const _ = (c_uid: number) => {
        if (categoryWillAdd.main === c_uid) {
          setCategoryWillAdd(prevState => ({
            ...prevState,
            main: -1,
            sub: -1,
            subSub: -1
          }));
        }
        else {
          setCategoryWillAdd(prevState => ({
            ...prevState,
            main: c_uid
          }));
        }

      }
      const depth1 = categories.filter(item => item.c_depth === 1)
      const selected = categoryWillAdd.main === depth1[rowIndex].c_uid;

      return (
        <div className={`cursor-pointer font-bold flex justify-center items-center gap-2 h-10  border-gray-200 ${selected ? 'bg-gray-300' : ''} `}>
          <div onClick={() => _(depth1[rowIndex].c_uid)} className=''>
            {rowData[column.key]} {'('}
            {depth1[rowIndex].c_uid}
            {')'}
            {'-'}
            {depth1[rowIndex].c_parent}
          </div>
          <div className='flex flex-col justify-center'>
            <MdDelete
              className='p-1 w-full h-full rounded hover:bg-green-200'
              onClick={() => deleteCategoryItem(depth1[rowIndex].c_uid)}
            />
          </div>
        </div>
      );
    };

    const subCell = (rowIndex: number, rowData: TableData, column: TableColumn, columnIndex: number) => {

      const _ = (c_uid: number) => {
        if (categoryWillAdd.sub === c_uid) {
          setCategoryWillAdd(prevState => ({
            ...prevState,
            sub: -1,
            subSub: -1
          }));
        }
        else {
          setCategoryWillAdd(prevState => ({
            ...prevState,
            sub: c_uid
          }));
        }
        ;
      }

      const depth2 = categories.filter(item => Number.parseInt(item.c_parent) === categoryWillAdd.main)

      const selected = categoryWillAdd.sub === depth2[rowIndex].c_uid;
      return (
        <div className={`cursor-pointer font-bold flex justify-center items-center gap-2 h-10  border-gray-200 ${selected ? 'bg-gray-300' : ''}`}>
          <div onClick={() => _(depth2[rowIndex].c_uid)} >
            {rowData[column.key]}
            {'('}
            {depth2[rowIndex].c_uid}
            {')'}
            {'-'}
            {depth2[rowIndex].c_parent}
          </div>
          <div className='flex flex-col justify-center'>
            <MdDelete
              className='p-1 w-full h-full rounded hover:bg-green-200'
              onClick={() => deleteCategoryItem(depth2[rowIndex].c_uid)}
            />
          </div>
        </div >
      );
    };

    const subSubCell = (rowIndex: number, rowData: TableData, column: TableColumn, columnIndex: number) => {

      const _ = (c_uid: number) => {
        if (categoryWillAdd.subSub === c_uid) {
          setCategoryWillAdd(prevState => ({
            ...prevState,
            subSub: -1
          }));
        }
        else {
          setCategoryWillAdd(prevState => ({
            ...prevState,
            subSub: c_uid
          }));
        }
        ;
      }

      const depth3 = categories.filter(item => Number.parseInt(item.c_parent.split(',')[1]) === categoryWillAdd.sub)
      const selected = categoryWillAdd.subSub === depth3[rowIndex].c_uid;
      return (
        <div className={`cursor-pointer font-bold flex justify-center items-center gap-2 h-10  border-gray-200 ${selected ? 'bg-gray-300' : ''}`}>
          <div onClick={() => _(depth3[rowIndex].c_uid)}>
            {rowData[column.key]}
            {'('}
            {depth3[rowIndex].c_uid}
            {')'}
            {'-'}
            {depth3[rowIndex].c_parent}
          </div>
          <div className='flex flex-col justify-center'>
            <MdDelete
              className='p-1 w-full h-full rounded hover:bg-green-200'
              onClick={
                () => deleteCategoryItem(depth3[rowIndex].c_uid)
              }
            />
          </div>
        </div>
      );
    };

    const addCategoryItem = async (type: string) => {
      setCurrentType(type);
      setShowCModal(true);
    };

    const deleteCategoryItem = async (c_uid: number) => {
      // console.log('delete', c_uid);
      await removeCategory(c_uid);
      await fetchCategories({ pageSize: 8192 });
    }

    const isMainCategorySelected = categoryWillAdd.main !== -1;
    const isSubCategorySelected = categoryWillAdd.sub !== -1;

    const modal = (
      <ModalBase>

        <div className='p-10 bg-white rounded-lg flex flex-col w-[900px] '>
          <div className='w-full text-center font-bold text-lg'>적용할 분류 선택</div>

          <div className='h-5'></div>
          <div className='flex'>

            <TableComponent
              columns={[{ key: 'c_name', label: '대분류', width: 150 },]}
              data={categories.filter(item => item.c_depth === 1)}
              className="w-1/3 border-[1px] rounded-tl-lg rounded-bl-lg  bg-gray-100"
              renderCell={mainCell}
              renderColumn={column}
              maxHeight={200}
              onAddNewRow={() => addCategoryItem('대분류')}
            />
            <TableComponent
              columns={[{ key: 'c_name', label: '중분류', width: 150 },]}
              data={categories.filter(item => item.c_depth === 2 && Number.parseInt(item.c_parent) === categoryWillAdd.main)}
              className="w-1/3 border-t-[1px] border-b-[1px] bg-gray-100"
              renderCell={subCell}
              renderColumn={subColumn}
              maxHeight={200}
              onAddNewRow={isMainCategorySelected ? () => addCategoryItem('중분류') : undefined}
            /><TableComponent
              columns={[{ key: 'c_name', label: '소분류', width: 150 },]}
              data={categories.filter(item => item.c_depth === 3 && Number.parseInt(item.c_parent.split(',')[1]) === categoryWillAdd.sub)}
              className="w-1/3 border-[1px] rounded-tr-lg rounded-br-lg bg-gray-100"
              renderCell={subSubCell}
              renderColumn={subsubColumn}
              maxHeight={200}
              onAddNewRow={isSubCategorySelected ? () => addCategoryItem('소분류') : undefined}
            />
          </div>

          <div className='h-5'></div>
          <div className='flex justify-end gap-2'>
            <button onClick={() => {
              categoryUpdate()
              setShowModal(false)
            }} className=' bg-green-500  rounded-lg whitespace-nowrap px-4 py-2 text-white font-bold '>설정</button>
            <button onClick={() => setShowModal(false)} className=' bg-gray-500  rounded-lg whitespace-nowrap px-4 py-2 text-white font-bold '>취소 </button>
          </div>
        </div>
      </ModalBase>
    )

    return modal;
  }

  const handleConfirm = async (name: string, type: string) => {
    const data = {
      c_name: name,
      c_view: 'Y',
      c_img: '',
      c_ima_on: '',
      c_depth: 1,
      c_idx: 1,
      c_parent: '',
      c_pro_cnt: 0,
      c_prefix_str: '새로운',
    };

    if (currentType === '대분류') {
      setCategoryWillAdd(prevState => ({
        ...prevState,
        main: -1,
        sub: -1,
        subSub: -1
      }));
      data.c_depth = 1;
      data.c_parent = '0';
    } else if (currentType === '중분류') {
      if (categoryWillAdd.subSub !== -1) {
        setCategoryWillAdd(prevState => ({
          ...prevState,
          sub: -1,
          subSub: -1
        }));
      } else {
        setCategoryWillAdd(prevState => ({
          ...prevState,
          sub: -1
        }));
      }
      data.c_depth = 2;
      data.c_parent = categoryWillAdd.main.toString();
    } else if (currentType === '소분류') {
      setCategoryWillAdd(prevState => ({
        ...prevState,
        subSub: -1
      }));
      data.c_depth = 3;
      data.c_parent = `${categoryWillAdd.main},${categoryWillAdd.sub}`;
    }

    // 여기서 data를 서버에 전송하거나 추가하는 로직을 작성
    await addCategory(data);

    setShowCModal(false);
  };

  useEffect(() => {
    console.log('a2');
    const _ = async () => {
      await fetchFilter({ select: 'p_cuid,p_brand,p_name,p_price,p_shoppingPayPdPrice,p_shoppingPay', pageSize: 8192 })

      await fetchCategories({ pageSize: 8192 })
      await fetchProducts({ page: 1, pageSize: 10 })
      await fetchKeywords({})
    }
    _();
  }, []);

  const addItem = async () => {
    const row: SmartProduct = {
      p_code: 'P12345',
      p_cuid: 1,
      p_cpid: '',
      p_stock: 0,
      p_salecnt: 50,
      p_price: 0,
      p_point_per: 5.0,
      p_name: '',
      p_subname: '',
      p_view: 'N',
      p_option_valid_chk: 'Y',
      p_newview: 'N',
      p_saleview: 'N',
      p_bestview: 'N',
      p_content: '',
      p_content_m: 'T',
      p_rdate: new Date(),
      p_img_list: '',
      p_img_list_over: '',
      p_img_b1: '',
      p_img_b2: '',
      p_img_b3: '',
      p_img_b4: '',
      p_img_b5: '',
      p_img_list2: '',
      p_img_list_square: '',
      p_coupon: '',
      p_idx: 1,
      p_option_type_chk: '1depth',
      p_shorten_url: '',
      p_orgin: '',
      p_maker: '',
      p_shoppingPay_use: 'Y',
      p_shoppingPay: 0,
      p_shoppingPayFree: 0,
      p_shoppingPayPdPrice: 0,
      p_shoppingPayPfPrice: 0,
      p_commission_type: '',
      p_sPersent: 0,
      p_sPrice: 0,
      p_icon: '',
      p_screenPrice: 11000,
      p_delivery_info: '',
      p_relation: '',
      p_relation_type: 'none',
      p_naver_switch: 'Y',
      p_daum_switch: 'Y',
      p_mdate: new Date(),
      p_hashtag: '',
      p_hashtag_shuffle: 'N',
      npay_use: 'Y',
      p_vat: 'Y',
      p_sort_group: 0,
      p_sort_idx: 0,
      p_use_content: 'Y',
      p_brand: 0,
      p_groupset_use: 'Y',
      p_free_delivery_event_use: 'N',
      p_option1_type: 'color',
      p_option2_type: 'size',
      p_option3_type: 'normal',
      p_soldout_chk: 'N',
      p_option1_title: 'Color Options',
      p_option2_title: 'Size Options',
      p_option3_title: 'Additional Options',
      p_sale_type: 'A',
      p_sale_sdate: undefined,
      p_sale_edate: undefined,
      p_sale_sdateh: 9,
      p_sale_sdatem: 0,
      p_sale_edateh: 18,
      p_sale_edatem: 0,
      p_expire_type: 'date',
      p_expire_day: 30,
      p_expire_date: undefined,
      p_type: 'delivery',
      p_dateoption_use: 'Y',
      p_dateoption_date_type: 'date',
      p_dateoption_sday: 1,
      p_dateoption_eday: 30,
      p_dateoption_sdate: undefined,
      p_dateoption_edate: undefined,
      p_dateoption_stime: '09:00',
      p_dateoption_etime: '18:00',
      p_dateoption_ex_week: ['1', '2', '3'],
      p_dateoption_ex_date: '2024-07-04,2024-07-11',
      p_buy_limit: 5,
      p_duplicate_use: 'N',
      p_com_juso: 'Seoul, Korea',
      p_com_mapx: '37.5665',
      p_com_mapy: '126.9780',
      p_apply_mapxy: 'Y',
      p_com_tel: '010-1234-5678',
      p_com_locname: 'Example Location',
      p_time_sale: 'N',
      p_time_sale_sdate: undefined,
      p_time_sale_sclock: '09:00',
      p_time_sale_edate: undefined,
      p_time_sale_eclock: '18:00',
      p_com_time: '09:00-18:00',
      per_price: 0,
      link: '',
    };

    if (rowWillAdd.p_name === '' || rowWillAdd.p_price === -1 || rowWillAdd.per_price === -1 || rowWillAdd.p_shoppingPay === -1) {
      alert('값을 입력해주세요');
      return;
    }

    let brandId = -1;
    const data = await fetchBrands({ c_name: rowWillAdd.p_brand });

    console.log("data", data)

    if (data.length === 0) {

      const newData = await addBrand({
        c_name: rowWillAdd.p_brand,
        c_view: "Y",
        c_img: undefined,
        c_depth: 1,
        c_idx: 1,
        c_parent: null,
        c_pro_cnt: 0,
        c_prefix_str: rowWillAdd.p_brand[0]
      })

      console.log("newData", newData)
      brandId = newData.c_uid;

    }
    else {
      brandId = data[0].c_uid;

    }

    let tempoKey = generateKey();


    let len = Infinity;
    while (len > 0) {
      const data = await fetchProducts({ p_code: tempoKey });
      len = data.length;
      tempoKey = generateKey();
    }

    let p_cuid: number = -1;

    if (categoryWillAdd.main !== -1) {
      p_cuid = categoryWillAdd.main;
    }
    if (categoryWillAdd.sub !== -1) {
      p_cuid = categoryWillAdd.sub;
    }
    if (categoryWillAdd.subSub !== -1) {
      p_cuid = categoryWillAdd.subSub;
    }
    row.p_cuid = p_cuid;
    row.p_code = tempoKey;
    row.p_name = rowWillAdd.p_name;
    row.p_brand = brandId;
    row.p_price = rowWillAdd.p_price;
    row.per_price = rowWillAdd.per_price;
    row.p_shoppingPay = rowWillAdd.p_shoppingPay;
    row.link = rowWillAdd.link;

    console.log("row", row);
    await addProdcut(row);

    window.location.reload();
  }

  useEffect(() => {
    console.log('엑셀 등록', xlsxRows);
    if (xlsxRows) {
      const _ = async () => {
        const row: SmartProduct = {
          p_code: 'P12345',
          p_cuid: 1,
          p_cpid: '',
          p_stock: 0,
          p_salecnt: 50,
          p_price: 0,
          p_point_per: 5.0,
          p_name: '',
          p_subname: '',
          p_view: 'N',
          p_option_valid_chk: 'Y',
          p_newview: 'N',
          p_saleview: 'N',
          p_bestview: 'N',
          p_content: '',
          p_content_m: 'T',
          p_rdate: new Date(),
          p_img_list: '',
          p_img_list_over: '',
          p_img_b1: '',
          p_img_b2: '',
          p_img_b3: '',
          p_img_b4: '',
          p_img_b5: '',
          p_img_list2: '',
          p_img_list_square: '',
          p_coupon: '',
          p_idx: 1,
          p_option_type_chk: '1depth',
          p_shorten_url: '',
          p_orgin: '',
          p_maker: '',
          p_shoppingPay_use: 'Y',
          p_shoppingPay: 0,
          p_shoppingPayFree: 0,
          p_shoppingPayPdPrice: 0,
          p_shoppingPayPfPrice: 0,
          p_commission_type: '',
          p_sPersent: 0,
          p_sPrice: 0,
          p_icon: '',
          p_screenPrice: 11000,
          p_delivery_info: '',
          p_relation: '',
          p_relation_type: 'none',
          p_naver_switch: 'Y',
          p_daum_switch: 'Y',
          p_mdate: new Date(),
          p_hashtag: '',
          p_hashtag_shuffle: 'N',
          npay_use: 'Y',
          p_vat: 'Y',
          p_sort_group: 0,
          p_sort_idx: 0,
          p_use_content: 'Y',
          p_brand: 0,
          p_groupset_use: 'Y',
          p_free_delivery_event_use: 'N',
          p_option1_type: 'color',
          p_option2_type: 'size',
          p_option3_type: 'normal',
          p_soldout_chk: 'N',
          p_option1_title: 'Color Options',
          p_option2_title: 'Size Options',
          p_option3_title: 'Additional Options',
          p_sale_type: 'A',
          p_sale_sdate: undefined,
          p_sale_edate: undefined,
          p_sale_sdateh: 9,
          p_sale_sdatem: 0,
          p_sale_edateh: 18,
          p_sale_edatem: 0,
          p_expire_type: 'date',
          p_expire_day: 30,
          p_expire_date: undefined,
          p_type: 'delivery',
          p_dateoption_use: 'Y',
          p_dateoption_date_type: 'date',
          p_dateoption_sday: 1,
          p_dateoption_eday: 30,
          p_dateoption_sdate: undefined,
          p_dateoption_edate: undefined,
          p_dateoption_stime: '09:00',
          p_dateoption_etime: '18:00',
          p_dateoption_ex_week: ['1', '2', '3'],
          p_dateoption_ex_date: '2024-07-04,2024-07-11',
          p_buy_limit: 5,
          p_duplicate_use: 'N',
          p_com_juso: 'Seoul, Korea',
          p_com_mapx: '37.5665',
          p_com_mapy: '126.9780',
          p_apply_mapxy: 'Y',
          p_com_tel: '010-1234-5678',
          p_com_locname: 'Example Location',
          p_time_sale: 'N',
          p_time_sale_sdate: undefined,
          p_time_sale_sclock: '09:00',
          p_time_sale_edate: undefined,
          p_time_sale_eclock: '18:00',
          p_com_time: '09:00-18:00',
          link: ''
        };

        for (let index = 0; index < xlsxRows.length; index++) {
          const xlsxRow = xlsxRows[index];

          console.log(xlsxRow)

          row.p_name = xlsxRow['제품명']
          // row.p_stock = Number.parseInt(xlsxRow['개수'].toString().replace(/,/g, ''))
          row.p_price = Number.parseInt(xlsxRow['물건 금액'].toString().replace(/,/g, '')); /* xlsxRow['물건 금액'] */
          row.per_price = Number.parseInt(xlsxRow['개수당 가격'].toString().replace(/,/g, ''));  /* xlsxRow['개수당 가격'] */
          row.p_shoppingPay = xlsxRow['배송비']
          row.link = xlsxRow['링크']

          let brandId = -1;

          const data = await fetchBrands({ c_name: xlsxRow[1] });
          console.log('data', data)
          if (data.length === 0) {
            const newData = await addBrand({
              c_name: xlsxRow['브랜드명'],
              c_view: "Y",
              c_img: undefined,
              c_depth: 1,
              c_idx: 1,
              c_parent: null,
              c_pro_cnt: 0,
              c_prefix_str: xlsxRow['브랜드명']
            })
            console.log('newData', newData)
            brandId = newData.c_uid;
          }
          else {
            brandId = data[0].c_uid;
          }
          row.p_brand = brandId
          let tempoKey = generateKey();
          let len = Infinity;
          while (len > 0) {
            const data = await fetchProducts({ p_code: tempoKey });
            len = data.length;
            tempoKey = generateKey();
          }
          row.p_code = tempoKey;


          let subsub = await fetchCategories({ c_name: xlsxRow['소분류'] })


          if (subsub !== undefined && subsub.length > 0) {
            row.p_cuid = subsub[0].c_uid;
            console.log(row.p_cuid)
          }

          console.log('올블루', row)
          const response = await fetch('/api/smart_product_comparison'
            , {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              // credentials: 'include',
              body: JSON.stringify(row),
            });


          console.log('원피스', response)
        }

        // window.location.reload();
      }

      _();
    }
  }, [xlsxRows]);

  // useEffect(() => {
  //   fetchProducts({ page: activePage, pageSize: 10 })
  // }, [activePage]);

  // useEffect(() => {
  //   refreshResult();
  // }, [selectedCategories, params]);

  useEffect(() => {
    const newParams = { ...params };
    let category = -1;

    if (selectedCategories.main !== -1) {
      category = selectedCategories.main;
    }
    if (selectedCategories.sub !== -1) {
      category = selectedCategories.sub;
    }
    if (selectedCategories.subSub !== -1) {
      category = selectedCategories.subSub;
    }
    if (category !== -1) {
      newParams['p_cuid'] = category;
    }

    if (searchKeyword !== '' && searchKeyword !== undefined) {
      newParams.search = searchKeyword
    }

    newParams.page = activePage;
    newParams.pageSize = 10;

    const fetchData = async () => {
      await fetchCategories({ pageSize: 8192 });

      if (searchKeyword === '' || searchKeyword === undefined) {
        await fetchProducts(newParams);
        await fetchFilter({ select: 'p_cuid,p_brand,p_name,p_price,p_shoppingPayPdPrice,p_shoppingPay', pageSize: 8192 })
      } else {
        await fetchFilter({ select: 'p_cuid,p_brand,p_name,p_price,p_shoppingPayPdPrice,p_shoppingPay', pageSize: 8192 })
        await fetchProducts(newParams);
      }
    }

    fetchData();
  }, [searchKeyword, activePage, selectedCategories, params]);

  const refreshResult = async () => {
    console.log('refreshResult');
    const newParams = { ...params };
    let category = -1;

    if (selectedCategories.main !== -1) {
      category = selectedCategories.main;
    }
    if (selectedCategories.sub !== -1) {
      category = selectedCategories.sub;
    }
    if (selectedCategories.subSub !== -1) {
      category = selectedCategories.subSub;
    }
    if (category !== -1) {
      newParams['p_cuid'] = category;
    }

    if (searchKeyword !== '' && searchKeyword !== undefined) {
      newParams.search = searchKeyword
    }

    newParams.page = activePage;
    newParams.pageSize = 10;

    console.log('newParams', newParams);
    await fetchCategories({ pageSize: 8192 })
    await fetchProducts(newParams);
    await fetchFilter({ select: 'p_cuid,p_brand,p_name,p_price,p_shoppingPayPdPrice,p_shoppingPay', pageSize: 8192 })
  }

  const handleFile = async (file: File) => {
    if (!file) {
      console.log('파일이 없습니다.');
      return;
    }

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];
    const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const headers = json[0] as string[];
    const rows = json.slice(1)
      .filter((row: any) => row.some((cell: any) => cell !== undefined && cell !== null && cell !== ''))
      .map((row: any) => {
        const arrayRow = row as any[];
        return arrayRow.reduce((acc: Record<string, any>, value, index) => {
          acc[headers[index]] = value;
          return acc;
        }, {});
      });

    setXLSXRow(rows);

    setIsVisible(false);
  };


  const handleDropdown = async (value: string, key: string) => {
    if (key === 'p_shoppingPayPdPrice' || key === 'p_shoppingPayPdPrice' || key === 'p_total') {
      key = 'p_price'
    }
    if (key === 'category') {

      if (value !== 'none') {
        setSelectedCategories(prevState => ({
          ...prevState,
          main: Number.parseInt(value)
        }));
      }
      else {
        setSelectedCategories(prevState => ({
          ...prevState,
          main: -1
        }));
      }

    }
    else if (key === 'sub_category') {
      if (value !== 'none') {
        setSelectedCategories(prevState => ({
          ...prevState,
          sub: Number.parseInt(value)
        }));
      }
      else {
        setSelectedCategories(prevState => ({
          ...prevState,
          sub: -1
        }));
      }
    }
    else if (key === 'sub_sub_category') {
      if (value !== 'none') {
        setSelectedCategories(prevState => ({
          ...prevState,
          subSub: Number.parseInt(value)
        }));
      }
      else {
        setSelectedCategories(prevState => ({
          ...prevState,
          subSub: -1
        }));
      }
    }

    if (key === 'category' || key === 'sub_category' || key === 'sub_sub_category') {
      return;
    }

    if (value === 'none') {
      const { [key]: _, ...rest } = params;
      setParams(rest);
    }
    else {
      setParams(prevParams => ({
        ...prevParams,
        [key]: value
      }));

    }

    console.log('test', params);
  };


  // Table의 각 셀을 그리는 함수
  const mainTableCell = (rowIndex: number, rowData: TableData, column: TableColumn, columnIndex: number) => {

    let border = 'font-bold flex justify-center items-center border-[#EBEBEB] '

    if (rowIndex !== 9) {
      border += 'border-b-[1px] '
    }

    if (columnIndex === 0) {
      border += 'border-r-[1px] border-l-[1px] '
    }
    else {
      border += 'border-r-[1px] '
    }

    if (column.key === 'delete') {
      const _ = async (p_code: string) => {

        const response = await fetch('/api/smart_product_comparison', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ p_code })
        });
        refreshResult();
      }

      return (<div className={`${border} cursor-pointer h-10 flex justify-center items-center`}><MdDelete onClick={() => _(rowData['p_code'])} size={20} /></div>)
    }
    let text = rowData[column.key]

    if (column.key === 'brand') {
      if (rowData[column.key])
        text = rowData[column.key].c_name
    }


    if (column.key === 'category' && categories.length > 0) {
      const obj = categories.find(category => category.c_uid === Number.parseInt(rowData['p_cuid']))

      if (obj && obj.c_depth === 1) {
        text = obj.c_name;
      }
      if (obj && obj.c_depth === 2) {

        text = categories.find(category => category.c_uid === Number.parseInt(obj.c_parent)).c_name;
      }
      if (obj && obj.c_depth === 3) {
        text = categories.find(category => category.c_uid === Number.parseInt(obj.c_parent.split(',')[0])).c_name;
      }
    }
    if (column.key === 'sub_category' && categories.length > 0) {
      const obj = categories.find(category => category.c_uid === Number.parseInt(rowData['p_cuid']))

      if (obj && obj.c_depth === 2) {
        text = obj.c_name;
      }
      if (obj && obj.c_depth === 3) {
        text = categories.find(category => category.c_uid === Number.parseInt(obj.c_parent.split(',')[1])).c_name;

      }
    }
    if (column.key === 'sub_sub_category' && categories.length > 0) {
      const obj = categories.find(category => category.c_uid === Number.parseInt(rowData['p_cuid']))

      if (obj && obj.c_depth === 3) {
        text = obj.c_name
      }
    }

    if (column.key === 'p_price') {
      text = rowData['p_price']
    }
    if (column.key === 'p_shoppingPayPdPrice') {
      text = rowData['p_price'] + rowData['p_shoppingPayPdPrice']
    }

    if (column.key === 'p_total') {
      text = rowData['p_price'] + rowData['p_shoppingPay']
    }
    if (text == null) {
      text = ''
    }
    // 카테고리, 브랜드, 링크는 수정이 가능하도록 함
    if (column.key === 'category' || column.key === 'sub_category' || column.key === 'sub_sub_category') {
      return (
        <div onClick={() => {
          setShowModal(true);
          setRowWillUpate(rowData['p_code'])
        }}
          className={`${border} cursor-pointer underline h-10  text-blue-500`}>
          {text}
        </div>
      );
    }

    // 가격, 배송비, 총 가격은 수정이 가능하도록 함
    if (column.key === 'p_code' || column.key === 'p_total') {
      // text가 문자열이면 그대로 사용, 숫자면 콤마를 찍어주는 코드 작성해줘
      const formattedValue = (typeof text === 'number' ? text.toLocaleString() : text);
      return (
        <div className={`${border} h-10 text-gray-500`}>
          {formattedValue}
        </div>
      );
    }

    const inputKey = `${rowData['p_code']}-${column.key}`;
    const inputValue = inputValues[inputKey] !== undefined ? inputValues[inputKey] : text;

    // inputValue가 숫자일 경우 콤마를 찍고, 그렇지 않으면 그대로 사용
    const formattedValue = (typeof inputValue === 'number' ? inputValue.toLocaleString() : inputValue);

    return (
      <div className={`${border} h-10 border-gray-200`}>
        <input
          onChange={(e) => handleInputChange(rowData['p_code'], column.key, e.target.value)}
          onBlur={(e) => handleBlur(rowData['p_code'], column.key, e.target.value)}
          className='w-full h-full bg-transparent text-center'
          value={formattedValue} // formattedValue를 사용
        />
      </div>
    );
  };

  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});

  const handleInputChange = (p_code: string, key: string, value: string) => {
    console.log('handleInputChange');
    console.log(value);
    setInputValues(prev => ({
      ...prev,
      [`${p_code}-${key}`]: value
    }));
  };

  const toggleDropdown = (event: any) => {
    if (event.target.closest('.absolute')) return;
    setIsVisible(!isVisible);

  };

  const toggleDropdownAd = () => {
    setIsVisibleAd(!isVisibleAd);
  }

  /*   const handleBlur = (e: FocusEvent<HTMLInputElement>) => { */
  const handleBlur = async (id: string, col: string, value: string) => {
    console.log('handleBlur');
    let brandId = -1;
    if (col === 'brand') {
      const data = await fetchBrands({ c_name: value });


      if (data.length === 0) {
        const newData = await addBrand({
          c_name: rowWillAdd.p_brand,
          c_view: "Y",
          c_img: undefined,
          c_depth: 1,
          c_idx: 1,
          c_parent: null,
          c_pro_cnt: 0,
          c_prefix_str: rowWillAdd.p_brand[0]
        })
        brandId = newData.c_uid;

      }
      else {
        brandId = data[0].c_uid;

      }
      if (value !== '') {
        col = 'p_brand'
        value = brandId.toString();
      }

    }
    type Data = {
      id: string;
      [key: string]: string;
    };
    const data: Data = { id: id };
    data[col] = value;

    const response = await fetch('/api/smart_product_comparison', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      const result = await response.json();

    } else {

    }


    //await refreshResult()
  }

  const [rowWillUpate, setRowWillUpate] = useState<string>('');

  const [r, setR] = useState<string>('');

  const categoryUpdate = async () => {
    console.log("categoryWillAdd", categoryWillAdd);
    let p_cuid: number = -1;

    if (categoryWillAdd.main !== -1) {
      p_cuid = categoryWillAdd.main;
    }
    if (categoryWillAdd.sub !== -1) {
      p_cuid = categoryWillAdd.sub;
    }
    if (categoryWillAdd.subSub !== -1) {
      p_cuid = categoryWillAdd.subSub;
    }

    if (rowWillUpate === '') {

    }
    else {
      const data = {
        id: rowWillUpate,
        p_cuid,
      }
      console.log(data);
      const response = await fetch('/api/smart_product_comparison', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Update successful:', result);
      } else {
        console.error('Update failed:', response.statusText);
      }

      await refreshResult()

      setCategoryWillAdd({ main: -1, sub: -1, subSub: -1 })
    }

  }



  const mainTableColumn = (column: TableColumn, index: number) => {

    let border = 'border-b-[1px] ';

    if (index === 0) {
      border += ' border-l-[1px]'
    }
    else if (index === 2) {
      border += 'border-l-[1px]  '
    }
    else {
      border += 'border-l-[1px]'
    }
    //{column.label}

    if (column.key === 'p_brand' ||
      column.key === 'p_name' ||
      column.key === 'p_price' ||
      column.key === 'p_shoppingPayPdPrice' ||
      column.key === 'p_shoppingPay' ||
      /*       column.key === 'p_total' || */
      column.key === 'category' ||
      column.key === 'sub_category' ||
      column.key === 'sub_sub_category' ||
      column.key === 'brand'
    ) {

      const options = [


      ];


      if (column.key === 'brand') {
        const a = filter.filter(item => item.brand !== null);

        const data = Array.from(new Set(a.map(f => f.brand.c_name)));


        for (let i = 0; i < data.length; i++) {

          options.push({
            key: data[i],
            label: data[i]
          })

        }

      } else if (column.key === 'p_name') {
        const data = Array.from(new Set(filter.map(f => f.p_name)));
        for (let i = 0; i < data.length; i++) {
          options.push({
            key: data[i],
            label: data[i]
          })

        }
      }

      else if (column.key === 'p_price') {
        const data = Array.from(new Set(filter.map(f => f.p_price)));
        for (let i = 0; i < data.length; i++) {
          options.push({
            key: data[i],
            label: (data[i])
          })

        }
      }
      else if (column.key === 'p_shoppingPayPdPrice') {
        const data = Array.from(new Set(filter.map(f => f.p_shoppingPayPdPrice + f.p_price)));
        for (let i = 0; i < data.length; i++) {
          options.push({
            key: data[i],
            label: (data[i])
          })

        }
      }
      else if (column.key === 'p_shoppingPay') {
        const data = Array.from(new Set(filter.map(f => f.p_shoppingPay)));
        for (let i = 0; i < data.length; i++) {
          options.push({
            key: data[i],
            label: (data[i])
          })

        }
      }
      /* else if (column.key === 'p_total') {
        const data = Array.from(new Set(filter.map(f => f.p_shoppingPay + f.p_price)));
        for (let i = 0; i < data.length; i++) {
          options.push({
            key: data[i],
            label: (data[i])
          })
 
        }
      } */
      else if (column.key === 'category') {
        const filtered = categories.filter(c => c.c_depth === 1)
        for (let i = 0; i < filtered.length; i++) {

          options.push(
            {
              key: filtered[i].c_uid,
              label: filtered[i].c_name
            });

        }
      }
      else if (column.key === 'sub_category') {
        const filtered = categories.filter(c => c.c_depth === 2)
        for (let i = 0; i < filtered.length; i++) {

          options.push(
            {
              key: filtered[i].c_uid,
              label: filtered[i].c_name
            });

        }
      }
      else if (column.key === 'sub_sub_category') {
        const filtered = categories.filter(c => c.c_depth === 3)
        for (let i = 0; i < filtered.length; i++) {

          options.push(
            {
              key: filtered[i].c_uid,
              label: filtered[i].c_name
            });

        }
      }

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });


      options.unshift({
        key: 'none',
        label: column.label,
      })

      return (
        <div className={`${border}  flex justify-center items-center h-10`}>
          <Dropdown
            onOptionChange={(value) => handleDropdown(value, column.key)}
            selectedOptionKey={'none'}
            options={options}
            buttonClassName="font-bold flex justify-between items-center rounded-lg  "
            dropdownClassName="h-96 overflow-y-scroll font-bold flex flex-col border-[1px] border-[#D8DDE3]   flex justify-between items-start rounded-lg bg-white "
            itemClassName="font-bold px-4 w-full h-[30px]"
            itemSelectedClassName="font-bold px-4 h-[30px] w-full  "
            itemHoverClassName="font-bold px-4 h-[30px] w-full "
          />
        </div>
      );
    }
    else {
      return (
        <div className={`${border}  flex justify-center items-center h-10`}>
          {column.label}
        </div>
      );
    }

  };


  // Table안의 Cell을 렌더링하는 함수
  function inputCell(rowIndex: number, rowData: TableData, column: TableColumn, columnIndex: number) {
    let border = 'font-bold flex justify-center items-center '

    let main = categories.filter(c => c.c_uid === categoryWillAdd.main)
    let sub = categories.filter(c => c.c_uid === categoryWillAdd.sub)
    let subSub = categories.filter(c => c.c_uid === categoryWillAdd.subSub)

    main = main.length === 0 ? '(설정)' : main[0].c_name
    sub = sub.length === 0 ? '(설정)' : sub[0].c_name
    subSub = subSub.length === 0 ? '(설정)' : subSub[0].c_name

    if (columnIndex === 0) {
      return (
        <div className='flex justify-start items-center h-10 border-b border-x border-gray-200 '>

          {/* <IoMdAddCircle onClick={addItem} className='cursor-pointer bottom-[128px]' size={50} /> */}
          <IoMdAdd className='w-full h-full cursor-pointer' onClick={addItem} />

        </div>);
    } else if (columnIndex === 9) {
      return (
        <div className='flex justify-start items-center h-10 border-b border-r border-gray-200 bg-gray-200 '>

        </div>
      );
    } else if (columnIndex === 11) {
      return (
        <div className='flex justify-start items-center h-10 border-b border-r border-gray-200 bg-gray-200 '>

        </div>
      );
    } else if (columnIndex === 3) {
      return (
        <div onClick={() => {
          setShowModal(true);
          setRowWillUpate('')
        }} className='flex justify-center text-blue-500 underline items-center bg-gray-200 h-10 border-b border-r border-gray-200 '>
          {main}
        </div>
      );
    } else if (columnIndex === 4) {
      return (
        <div onClick={() => {
          setShowModal(true);
          setRowWillUpate('')
        }} className='flex justify-center text-blue-500 underline items-center bg-gray-200 h-10 border-b border-r border-gray-200 '>
          {sub}
        </div>
      );
    } else if (columnIndex === 5) {
      return (
        <div onClick={() => {
          setShowModal(true);
          setRowWillUpate('')
        }} className='flex justify-center text-blue-500 underline items-center bg-gray-200 h-10 border-b border-r border-gray-200 '>
          {subSub}
        </div>
      );
    } else if (columnIndex === 1) {
      return (
        <div className='flex justify-start items-center h-10 border-b border-r border-gray-200 '>
          <input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setRowWillAdd(prevState => ({
                ...prevState,
                p_brand: e.target.value
              }));
            }} className='pl-4 w-full'>

          </input>
        </div>
      );
    } else if (columnIndex === 2) {
      return (
        <div className='flex justify-start items-center h-10 border-b border-r border-gray-200 '>
          <input onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setRowWillAdd(prevState => ({
              ...prevState,
              p_name: e.target.value
            }));
          }} className='pl-4 w-full'>

          </input>
        </div>
      );
    } else if (columnIndex === 10) {
      return (
        <div className='flex justify-start items-center h-10 border-b border-r border-gray-200 '>
          <input onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setRowWillAdd(prevState => ({
              ...prevState,
              link: e.target.value
            }));
          }} className='pl-4 w-full'>

          </input>
        </div>
      );
    } else if (columnIndex === 6) {
      return (
        <div className='flex justify-start items-center h-10 border-b border-r border-gray-200 '>
          <input type="number" min="0" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setRowWillAdd(prevState => ({
              ...prevState,
              p_price: Number.parseInt(e.target.value)
            }));
          }} className='pl-4 w-full'>

          </input>
        </div>
      );
    } else if (columnIndex === 7) {
      return (
        <div className='flex justify-start items-center h-10 border-b border-r border-gray-200 '>
          <input type="number" min="0" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setRowWillAdd(prevState => ({
              ...prevState,
              per_price: Number.parseInt(e.target.value)
            }));
          }} className='pl-4 w-full'>

          </input>
        </div>
      );
    } else if (columnIndex === 8) {
      return (
        <div className='flex justify-start items-center h-10 border-b border-r border-gray-200 '>
          <input type="number" min="0" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setRowWillAdd(prevState => ({
              ...prevState,
              p_shoppingPay: Number.parseInt(e.target.value)
            }));
          }} className='pl-4 w-full'>
          </input>
        </div>
      );
    }
  }

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
    console.log('원피스', searchKeyword);
  }

  function inputCol(column: TableColumn, index: number) {
    return (<></>)
  };

  const data = [{
    p_code: '',
    p_brand: '',
    p_name: '',
    category: '',
    sub_category: '',
    sub_sub_category: '',
    p_price: '',
    p_shoppingPayPdPrice: '',
    p_shoppingPay: '',
    p_total: ''
  }]

  const handleSubmit = async (form: string, file: File) => {
    const formData = new FormData();

    if (file)
      formData.append(form, file);
    /*     if(user_id)
          formData.append('cbcv_user_id',user_id.toString())
     */

    const response = await fetch('/api/upload', {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      console.log('File uploaded successfully');
    } else {
      console.error('Failed to upload file');
    }

  };

  const handleTagAdded = (newTag: any) => {
    delete newTag.id;
    addKeyword(newTag);
  };

  const handleTagRemoved = (removedTag: any) => {
    removeKeyword(removedTag.id);
  };

  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='w-full flex flex-col border-b border-[#F7F7F7] items-center'>
        <div className='flex h-[100px] align-middle items-center'>
          <Link to={'https://www.greendea.co.kr/'}><img className=' h-[45px]' src='/Greendea.svg' alt='logo'></img></Link>
        </div>
      </div>

      <div className='max-w-[1280px] items-center mx-auto'>
        {showModal && drawModal()}
        {showCModal && (
          <CNameModal
            isOpen={showCModal}
            onClose={() => setShowCModal(false)}
            onConfirm={(name) => handleConfirm(name, currentType)} // type에 따라 조정
          />
        )}
        <div className='pt-14 flex justify-between items-center text-[28px] font-semibold '>
          <span>관리자 페이지</span>
          <div className='flex text-sm gap-4'>
            <div
              className='relative px-9 py-2 rounded-full border border-[#52D998] text-[#52D998] cursor-pointer'
              onClick={toggleDropdownAd}
            >
              <span>광고 올리기</span>

              {isVisibleAd &&
                <div className='absolute top-8 mt-4 left-1/2 transform -translate-x-1/2 flex flex-col text-[#6E6E6E] bg-white rounded-xl shadow-lg'>
                  <FileUploadButton accept='.png,.jpg' onFileSelect={(file) => handleSubmit('left', file)}>
                    <div className="w-[140px] h-[40px] flex items-center justify-center ">
                      왼쪽광고
                    </div>
                  </FileUploadButton>
                  <FileUploadButton accept='.png,.jpg' onFileSelect={(file) => handleSubmit('right', file)}>
                    <div className="w-[140px] h-[40px] flex items-center justify-center ">
                      오른쪽 광고
                    </div>
                  </FileUploadButton>
                  <FileUploadButton accept='.png,.jpg' onFileSelect={(file) => handleSubmit('bottom', file)}>
                    <div className="w-[140px] h-[40px] flex items-center justify-center ">
                      하단 광고
                    </div>
                  </FileUploadButton>
                </div>
              }
            </div>
            <div
              className='relative px-9 py-2 rounded-full border border-[#52D998] bg-[#52D998] text-white cursor-pointer'
              onClick={toggleDropdown}
            >
              <span>파일 첨부</span>
              {isVisible &&
                <div className='absolute top-8 mt-4 left-1/2 transform -translate-x-1/2 flex flex-col text-[#6E6E6E] bg-white rounded-xl shadow-lg'>
                  <FileUploadButton accept='.xlsx' onFileSelect={handleFile}>
                    <div className=" w-[140px] h-[40px] flex items-center justify-center">
                      엑셀 올리기
                    </div>
                  </FileUploadButton>
                </div>
              }
            </div>


          </div>
        </div>

        <div className='flex h-[50px] mt-5'>
          <SearchBar
            searchKeyword={searchKeyword}
            handleSearchInput={handleSearchInputChange}
            refreshResult={refreshResult}
          />
        </div>

        <div className='w-full pt-4 flex justify-start items-center gap-2 text-sm'>
          <div className='font-bold whitespace-nowrap'> 추천 검색어: </div>
          <TagInput
            tags={keywords}
            toggled={keywords}
            displayProperty="keyword"
            onTagAdded={handleTagAdded}
            onTagRemoved={handleTagRemoved}
          />
        </div>

        <div className='pt-7 text-[#5E5E62] font-semibold text-sm'>
          <TableComponent
            columns={productColumns}
            data={products}
            className='w-full'
            renderCell={mainTableCell}
            renderColumn={mainTableColumn}
            maxHeight={400}
          />
          <TableComponent
            columns={productColumns}
            data={data}
            className='w-full'
            renderCell={inputCell}
            renderColumn={inputCol}
          />

          <div className='pt-12 pb-[68px]'>
            <Pagination
              activePage={productPagination.page}
              itemsCountPerPage={productPagination.pageSize}
              totalItemsCount={productPagination.totalCount}
              pageRangeDisplayed={5}
              prevPageText={'‹'}
              nextPageText={'›'}
              onChange={setActivePaged}
            />
          </div>
        </div>

      </div>

      <Footer />
    </div >
  );
}

export default ProductManagement;
import React, { useState, ChangeEvent, FormEvent, KeyboardEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface SearchBarProps {
  searchKeyword?: string;
  handleSearchInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  refreshResult?: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ searchKeyword, handleSearchInput, refreshResult }) => {
  return (
    <div className="flex w-full h-full items-center p-2 border border-[#D7D7D7] rounded-full px-6">
      <input
        className="bg-transparent text-black placeholder-[#B3B4B5] pl-2 w-full text-base font-medium"
        type="text"
        placeholder="찾고 싶은 브랜드 또는 상품을 검색해보세요!"
        value={searchKeyword}
        onChange={handleSearchInput}
      />
      <FontAwesomeIcon
        icon={faSearch}
        className='text-[#9EA5A1] cursor-pointer'
        onClick={refreshResult}
      />
    </div>

  );
};

export default SearchBar;

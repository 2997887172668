
export const extractPropertyArray = <T, K extends keyof T>(items: T[], key: K): T[K][] =>{
  return items.map(item => item[key]);
}
export const has24HoursPassed = (date1: Date, date2: Date): boolean => {
  const msPerDay = 24 * 60 * 60 * 1000; 
  const diff = Math.abs(date1.getTime() - date2.getTime()); 
  return diff > msPerDay;
};
export const formatKoreanPhoneNumber = (phoneNumber: string): string =>{
  const cleaned = ('' + phoneNumber).replace(/\D/g, ''); 
  const match = cleaned.match(/^(\d{3})(\d{3,4})(\d{4})$/); 

  if (match) {
      return match[1] + '-' + match[2] + '-' + match[3]; 
  }

  return phoneNumber; 
}
export const formatNumberWithCommas = (x: number): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const formatDateDifference = (
  startDate: Date,
  endDate: Date,
): string => {
  const timeDifference =
    new Date(endDate).getTime() - new Date(startDate).getTime();

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return `${years}년 전`;
  } else if (days > 0) {
    return `${days}일 전`;
  } else if (hours > 0) {
    return `${hours}시간 전`;
  } else if (minutes > 0) {
    return `${minutes}분 전`;
  } else {
    return `${seconds}초 전`;
  }
};

export const truncateString = (str: string, maxLength: number): string => {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.slice(0, maxLength) + '...';
  }
};

export function buildQueryParams(params: { [key: string]: any }): string {
  const queryParams = new URLSearchParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      queryParams.append(key, params[key]);
    }
  }
  return queryParams.toString();
}

export function formatDateTime(date: Date, delimiter: string = '-'): string {
  const pad = (num: number): string => num.toString().padStart(2, '0');

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // getMonth()는 0부터 시작하므로 1을 더해줍니다.
  const day = pad(date.getDate());
  const hour = pad(date.getHours());
  const minute = pad(date.getMinutes());

  return `${year}${delimiter}${month}${delimiter}${day} ${hour}:${minute}`;
}
export function clamp(number: number, min: number, max: number): number {
  return Math.min(Math.max(number, min), max);
}
export function formatDate(date: Date): string {
  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth() + 1;
  const day = new Date(date).getDate();

  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}

export const encodeFileToBase64 = (fileBlob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(fileBlob);
  return new Promise<string>((resolve) => {
    reader.onload = () => {
      resolve(reader.result as string);
    };
  });


};

export function base64ToBlob(base64: string, mime: string) {
  mime = mime || '';
  var sliceSize = 1024;
  var byteChars = window.atob(base64);
  var byteArrays = [];

  for (
    var offset = 0, len = byteChars.length;
    offset < len;
    offset += sliceSize
  ) {
    var slice = byteChars.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mime });
}
